.dashimg {
  /* height: 60vh;
    overflow: hidden; */
  background-image: url("../../../assets/Banners/group3.png");
  overflow: hidden;
  padding-top: 320px;
  padding-bottom: 230px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.documentRow {
  height: 200px;
}

.tabItem {
  color: #222648;

  float: left;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  border: none;
  padding: 5px 7px;
  margin-right: 10px;
  border-radius: 10px !important;
  cursor: pointer;

  width: auto;

  background: #4a1e75;
}
