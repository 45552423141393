@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css);
@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css);
@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css);
@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css);
@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css);
@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css);
@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css);
@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&family=Inter:wght@200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css);
.Dashboard_topCardLeft__1IO5S {
  float: left;
}
.Dashboard_viewstyle__2OFMb {
  border-radius: 40px;
  border: 2px solid #4a1d75;
  padding: 5px;
  background-color: #4a1d75 !important;
  /* margin-left: 2px; */
  color: #fff !important;
  text-decoration: none !important;
  /* width: 20vw; */
}



.Dashboard_dashimg__24k_F {
  /* width: 30vh;
  height: 100vh;
  overflow: hidden; */
  background-image: url(../../static/media/group3.e7c1db50.png);
  background-size: cover;
  color: white;
  text-align: center;
  /* width:px; */
  background-repeat: no-repeat;
  background-position: center;
  /* width: 100%; */
}
.Dashboard_dashtext__21Cgk {
  /* padding: 300px 0; */
  /* padding-top: 0px; */
  padding-top: 200px;
  padding-bottom: 350px;
}
@media only screen and (max-width: 1300px) {
  .Dashboard_dashtext__21Cgk {
    /* padding: 20px 0!important; */
    /* padding-top: 0px; */
    padding-top: 148px !important;
    padding-bottom: 248px !important;
  }
  .Dashboard_text__1F3D3 {
    color: #ffffff;
    font: inter;
    font-weight: 500;
    font-size: 18px !important;
    letter-spacing: 2px;
    /* color: red; */
    /* border:2px solid red!important */
    padding-top: 4px !important;
  }
  .Dashboard_text1__2zBuY {
    color: #fff;
    font: lato;

    letter-spacing: normal;
    /* font-size: 10px!important; */
    /* border:2px solid red!important */
  }
  /* .learnMedia{
  top: 50%;
}; */
  .Dashboard_learn__3qStY {
    font-size: 100px !important;
    font-weight: 500;
    letter-spacing: 1px;
  }
  .Dashboard_in__2OaW2 {
    font-size: 100px !important;
    color: #ffffff;
    letter-spacing: normal;
    /* font-size: 10px!important */
  }
  .Dashboard_new__1NZRa {
    font-size: 100px !important;
    color: #ffffff;
    font-family: lato;
    letter-spacing: normal;
  }
  .Dashboard_text2__3tIbY {
    color: #fff;
    font-family: inter;
    /* letter-spacing: 15px; */
    font-size: 17px !important;
    /* border:2px solid red!important */
  }
  .Dashboard_course__3o0gL {
    color: #fff;
    font-family: inter;
    font-size: 17px !important;
  }
}
.Dashboard_text__1F3D3 {
  color: #ffffff;
  font: inter;
  font-weight: 400;
  font-size: 25px;
  letter-spacing: 2px;
  /* border:2px solid green */
  /* padding-bottom: 20px; */
}
.Dashboard_text1__2zBuY {
  color: #fff;
  font: lato;

  letter-spacing: normal;
  /* border:2px solid green */
}
.Dashboard_learn__3qStY {
  color: #c5f9d5;
  font-size: 140px;
  font-weight: 500;
  letter-spacing: 0;
}
.Dashboard_in__2OaW2 {
  font-size: 140px;
  color: #ffffff;
  letter-spacing: normal;
}
.Dashboard_new__1NZRa {
  font-size: 140px;
  color: #ffffff;
  font-family: lato;
  letter-spacing: normal;
}
.Dashboard_text2__3tIbY {
  color: #fff;
  font-family: inter;
  font-size: 24px;
  /* border:2px solid green */
}
.Dashboard_course__3o0gL {
  color: #fff;
  font-family: inter;
  font-size: 24px;
}
.Dashboard_buymore__2l5Y_ {
  padding: 5px;
  font-size: 10px;
  height: 30px;
}
.Dashboard_topCardLeft__1IO5S h5 {
  font-size: 14px;
  margin-bottom: 12px;
  text-align: left;
  color: #9c9c9c;
  font-weight: 400;
}

.Dashboard_topCardLeft__1IO5S h2 {
  font-size: 17px;
  margin-bottom: 10px;
  margin-top: 0;
}

.Dashboard_topCardLeft__1IO5S span {
  font-size: 12px;
  background: #222648;
  padding: 5px 10px;
  border-radius: 10px;
  color: #fff;
  letter-spacing: 1px;
}

.Dashboard_topCardRight__1Sbw0 {
  float: right;
  padding: 15px 0;
  padding-bottom: 0px;
}
.Dashboard_carding__1XVVW {
  width: 18vw;
  height: auto;
  padding: 2px;
  background-color: #c5f9d5;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
.Dashboard_assignmentDiv1__3FgmZ {
  padding: 1px !important;
  background-color: #aa96db !important;
  /* border-radius: 8px!important; */
  text-align: center;
  width: 100%;
  height: 12vh;
  justify-content: center;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
.Dashboard_sessionsDiv__1CzTH {
  /* padding:1px!important; */
  background-color: #ffffd3 !important;
  /* background-color: #C5F9D5!important; */
  /* border-radius:0 0 8px 8px!important; */
  text-align: center;
  width: 18vw;
  height: 25vh;
  justify-content: center;
  color: black;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
.Dashboard_sessionbutton__2WWn7 {
  background-color: #ab94e2 !important;
  border-radius: 40px !important;
  width: 80px !important;
  height: 30px !important;
}
.Dashboard_topCardRight__1Sbw0 img {
  width: 58px;
}

.Dashboard_assignmentDiv__wjvpz h5,
.Dashboard_queriesDiv__3sq3k h5 {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-bottom: 5px;
}

.Dashboard_assignmentDiv__wjvpz p {
  display: inline-block;
  font-size: 14px;
  color: #686f7a;
  margin-bottom: 0;
  margin-top: 3px;
}

.Dashboard_queriesDiv__3sq3k p {
  display: inline-block;
  font-size: 14px;
  color: #686f7a;
  margin-bottom: 0;
  line-height: 27px;
}

.Dashboard_hr__3WkKW {
  margin-top: 10px;
  margin-bottom: 10px;
}

.Dashboard_curriculum__23Z5f {
  margin-right: 10px;
  font-size: 15px;
}

.Dashboard_subject__YBrwr,
.Dashboard_chapter__wJATE {
  float: left;
  margin-right: 10px;
  font-size: 15px;
}

.Dashboard_queryDiv__1ZzLq {
  /* line-height: 28px; */
  padding: 10px !important;
  background-color: #c5f9d5 !important;
  border-radius: 8px !important;
  text-align: center;
  width: 18vw;
  /* height: 18vh; */
  margin-bottom: 20px;
  height: auto;
  /* margin-bottom: 10px; */
}

/* .queryDiv .subject {
  float: none;
  margin-right: 10px;
  font-size: 14px;
  width: 100%;
} */

/* .queryDiv .topic {
  float: none;
  margin-right: 10px;
  font-size: 14px;
} */

/* .queryDiv .chapter {
  margin-right: 10px;
  font-size: 14px;
} */

.Dashboard_sessionsDiv__1CzTH h2 {
  font-size: 15px;
  margin-bottom: 5px;
  margin-top: 0;
  color: #333;
}

.Dashboard_sessionsDiv__1CzTH p {
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 0;
}

.Dashboard_sessionsDiv__1CzTH span {
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 10px;
  color: #fff;
  letter-spacing: 1px;
}

.Dashboard_sessionsDiv__1CzTH button {
  margin-top: 12px;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.16), 0 0px 0px 0 rgba(0, 0, 0, 0.12);
  background-color: #ea9f07;
  border-color: #ea9f07;
  width: 100%;
  padding: 5px;
  border-radius: 3px;
}
.Dashboard_studentDash__3I1vk {
  display: flex;
  justify-content: space-between;
  width: 96% !important;
}
.Dashboard_studentDash1__194qA {
  display: flex;
  justify-content: space-between;
  width: 95% !important;
}
.Dashboard_studentdashCurrentCurriculum__SVnmC {
  display: flex;
  justify-content: space-between;
  width: 96% !important;
  margin-bottom: 10px;
}
.Dashboard_row1__2sKr8 {
  display: flex !important;
  /* width: 100%; */
  /* justify-content: space-around; */
  /* flex-wrap: wrap; */
  /* padding: 20px; */
  /* margin-right: 60px; */
  overflow: scroll !important;
  /* -ms-overflow-style: none;
  scrollbar-width: none; */
  width: 100%;
}
.Dashboard_row1__2sKr8::-webkit-scrollbar {
  height: 0px !important;
  width: 0px !important;
  border: 1px solid #d5d5d5 !important;
}

.Dashboard_main_box__15sr_ {
  background: #c5f9d5;
  min-width: 380px;
  margin-right: 20px;
  border-radius: 30px;
}

.Dashboard_box_img__1fRmr {
  /* margin: 0 auto !important; */
  height: 60px;
  width: 60px;
}

.Dashboard_box_img1__1oWl9 {
  width: 90px;
  margin-top: -10px;
}
.Dashboard_box_img2__3i3it {
  width: 72px;
  margin-top: -10px;
}
.Dashboard_box_con__UpMDY{
 width: 100%;
 display: flex;
 overflow-x: auto;
 border: 1px solid green;
}

.Dashboard_box__2dJWh{
  width: 400px;
  border: 1px solid red;
  margin-right: 50px;
}

.Dashboard_heading__N7ALw {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.Dashboard_degname__1xFx0 {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
}

.Dashboard_headingT__IG3YK {
  font-size: 25px;
  font-weight: 600;
  color: #20639b;
}

.Dashboard_code__3EjfO {
  color: #20639b;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}

.Dashboard_datebox__L2U6p {
  background: #4a1d75;
  color: white;
  border-radius: 30px;
  padding: 10px 10px;
}

.Dashboard_gani__9EeVa{
  overflow-y: scroll;
}

.Dashboard_para1__7XbB3{
  background-color: rgb(228, 221, 221);
  padding: 10px;
  font-size: 15px;
  font-weight: 500;
  border-radius: 5px;
}



.share_cardDiv__2qF0K {
 /* before */
  /* background: rgb(255, 255, 255); */
  /* after */
  background: #F5F5F5;
  width: 100%;
  border-radius: 6px;
  border: 2px solid rgb(238, 238, 238);
  padding: 15px;
  padding-top: 30px;
  text-align: center;
  /* align-items: center;
  justify-content: center;
  display: flex;
  font-size: 15px; */
}

.share_center__1p7Qu {
  font-weight: 400;
  margin-top: 3px;
}

.share_modalContent__3eFTM {
  background: transparent !important;
  border: 0px !important;
}

.share_modalBackdrop__1TqFU {
  opacity: 0.7 !important;
}

.MyQueries_queryDiv__2LtJd p {
  display: inline-block;
  font-size: 14px;
  color: black;
  margin-bottom: 0;
  line-height: 27px;
}
.MyQueries_selectstyle__ig67Z{
  border-radius: 40px;
}
.MyQueries_raisequerymain__3gQnQ{
  overflow-x: hidden !important;
}
.MyQueries_mysubmit__3ToWq{
  margin-left: 10px;
}
.MyQueries_dashimg__2fEme {
  background-image: url(../../static/media/group3.e7c1db50.png);
  /* height: 70vh; */
  overflow: hidden;
  padding-top: 340px;
  padding-bottom: 210px;
    background-repeat: no-repeat;
  background-position: center;
    background-size: cover;
  /* padding-bottom: 300px; */
}
.MyQueries_butonstyle__gShtn{
  /* padding: 0 20px; */
  display: flex;
  justify-content: space-between;
  width: 40%;
  
}
.MyQueries_buttonTab__ECMkh{
  width: 200px !important;
  text-align: center;
  /* border-radius: 40px; */
  border-radius: 30px !important;
  padding-top: 8px !important;
  /* font-size: 30px; */
  /* border: 2px solid green; */
}
.MyQueries_queryTypeButton__37yH2{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.MyQueries_hr__1hG29 {
  margin-top: 15px;
  margin-bottom: 10px;
  clear: both;
  /* width:100%; */
}
/* .cardinput{
  width: 100%;
} */

.MyQueries_curriculum__2cwAt{
   margin-left: 5px;
}
.MyQueries_subject__2W7q5{
   margin-left: 10px;
}
.MyQueries_topic__301XQ{
  margin-left: 10px;
}
.MyQueries_chapter__1u2Bs {
  float: left;
  margin-right: 10px;
  margin-top: 8px;
  font-size: 16px;
  /* line-height: 20px; */
   margin-left: 10px;
}

.MyQueries_main__YcBMn {
  width: 94vw;
  border-radius: 6px;
  /* border: 1px solid black; */
  margin-top: 25px;




}

.MyQueries_queryFeatures__1hl6t {
  /* background: #e9edf6; */
  background: #e9eaec;
  margin-top: 10px;
  padding: 10px;
  overflow: hidden;
}

.MyQueries_queryFeatures__1hl6t span {
  cursor: pointer;
  padding: 4px 10px;
  margin-left: 10px;
  border-radius: 2px;
  float: right;
  color: #fff;
  font-size: 13px;
  letter-spacing: 0.5px;
}

.MyQueries_queryFeatures__1hl6t .MyQueries_replay__12xpT {
  background: #AA96DB;
}

.MyQueries_queryFeatures__1hl6t .MyQueries_resolve__3PFyH {
  background: #66bb6a;
}

.MyQueries_queryFeatures__1hl6t .MyQueries_feedback__FSeTk {
  background: #42a5f5;
}

.MyQueries_raiseQueryDiv__d60en select {
  height: 42px;
}

.MyQueries_noReplay__roZ3e {
  color: #000 !important;
  font-size: 14px !important;
  font-weight: 500;
}

.MyQueries_studentReview__2sZ5J {
  background: #e6f3fd;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  margin: 10px 0;
}

.MyQueries_teacherReview__3IJnC {
  background: #fff2e8;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  margin: 10px 0px;
}

.MyQueries_messagesDiv__1uV2Q {
  max-height: 250px;
  overflow-y: auto;
}

/* width */
.MyQueries_queryDiv__2LtJd ::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.MyQueries_queryDiv__2LtJd ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px #ccc;
  border-radius: 10px;
}

/* Handle */
.MyQueries_queryDiv__2LtJd ::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 10px;
}

/* Handle on hover */
.MyQueries_queryDiv__2LtJd ::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}

.MyQueries_open__3zuAW {
  display: block;
}

.MyQueries_close__z1jpu {
  display: none;
}

.MyQueries_cardDiv2__G2rbC {
  background-color: #ecf3ff;
  Width: 1170px;
  Height: 244px;
  Top: 437px;
  Left: 67px;
  border-radius: 6px;
  margin: 15px;
  border-radius: 6px;
}

.MyQueries_cardDiv3__yAqH4 {
  background-color: #ECF3FF;
  height: 190px;
  border-radius: 6px;
}








@media screen and (min-width: 280px) {
  .MyQueries_cardDiv3__yAqH4 {
    background-color: #ECF3FF;
    height: 190px;
    border-radius: 6px;
    width: 19%;
  }
}
@media screen and (min-width: 280px) {

  .MyQueries_curriculum__2cwAt,
  .MyQueries_subject__2W7q5,
  .MyQueries_topic__301XQ,
  .MyQueries_chapter__1u2Bs {
    float: left;
    /* margin-right: 3px; */
    margin-top: 8px;
    font-size: 9px;
    /* line-height: 20px; */
  }
}


@media screen and (min-width: 360px) {
  .MyQueries_cardDiv3__yAqH4 {
    background-color: #ECF3FF;
    height: 190px;
    border-radius: 6px;
    width: 25%;
  }
}

@media screen and (min-width: 360px) {

  .MyQueries_curriculum__2cwAt,
  .MyQueries_subject__2W7q5,
  .MyQueries_topic__301XQ,
  .MyQueries_chapter__1u2Bs {
    float: left;
    /* margin-right: 1px; */
    margin-top: 8px;
    font-size: 12px;
    /* line-height: 20px; */
  }
}

@media screen and (min-width: 375px) {
  .MyQueries_cardDiv3__yAqH4 {
    background-color: #ECF3FF;
    height: 190px;
    border-radius: 6px;
    width: 26%;
  }
}

@media screen and (min-width: 375px) {

  .MyQueries_curriculum__2cwAt,
  .MyQueries_subject__2W7q5,
  .MyQueries_topic__301XQ,
  .MyQueries_chapter__1u2Bs {
    float: left;
    margin-right: 1px;
    margin-top: 8px;
    font-size: 13px;
    /* line-height: 20px; */
  }
}
@media screen and (min-width: 412px) {
  .MyQueries_cardDiv3__yAqH4 {
    background-color: #ECF3FF;
    height: 190px;
    border-radius: 6px;
    width: 29%;
  }
}
@media screen and (min-width: 412px) {

  .MyQueries_curriculum__2cwAt,
  .MyQueries_subject__2W7q5,
  .MyQueries_topic__301XQ,
  .MyQueries_chapter__1u2Bs {
    float: left;
    margin-right: -1px;
    margin-top: 8px;
    font-size: 14px;
    /* line-height: 20px; */
  }
} 
@media screen and (min-width: 540px) {
  .MyQueries_cardDiv3__yAqH4 {
    background-color: #ECF3FF;
    height: 190px;
    border-radius: 6px;
    width: 40%;
  }
}
@media screen and (min-width: 768px) {
  .MyQueries_cardDiv3__yAqH4 {
    background-color: #ECF3FF;
    height: 190px;
    border-radius: 6px;
    width: 58%;
  }
}

@media screen and (min-width: 820px) {
  .MyQueries_cardDiv3__yAqH4 {
    background-color: #ECF3FF;
    height: 190px;
    border-radius: 6px;
    width: 62%;
  }
}

@media screen and (min-width: 1024px) {
  .MyQueries_cardDiv3__yAqH4 {
    background-color: #ECF3FF;
    height: 190px;
    border-radius: 6px;
    width: 79%;
  }
}

@media screen and (min-width: 912px) {
  .MyQueries_cardDiv3__yAqH4 {
    background-color: #ECF3FF;
    height: 190px;
    border-radius: 6px;
    width: 70%;
  }
}

@media screen and (min-width: 1280px) {
  .MyQueries_cardDiv3__yAqH4 {
    background-color: #ECF3FF;
    height: 190px;
    border-radius: 6px;
    width: 100%;
  }
}
.Assignments_dashimgstyle__2FjCf{
   background-image: url(../../static/media/group3.e7c1db50.png);
     overflow: hidden;
  padding-top: 360px;
  padding-bottom: 210px;
    background-repeat: no-repeat;
  background-position: center;
    background-size: cover;
}
@media only screen and (max-width: 1280px){
  .Assignments_dashimg__3u3Nd{
   /* background-image: url("../../../assets/Banners/group3.png"); */
     /* overflow: hidden; */
 /* padding-top: 100px; */
  /* padding-bottom: 200px; */
    background-repeat: no-repeat;
  background-position: center;
    background-size: cover;
}

}
.Assignments_assignmentDiv__1kjGr h5 {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-bottom: 5px;
  cursor: pointer;
}
/* .theam-color{
  background: #222648 !important;
} */
.Assignments_dashimg__3u3Nd {
  height: 60vh;
  overflow: hidden;
}

.Assignments_assignmentDiv__1kjGr p {
  display: inline-block;
  font-size: 14px;
  color: #686f7a;
  margin-bottom: 0;
  margin-top: 3px;
}

.Assignments_assignment-div__3-1dm span {
  color: #fff;
  width: 100%;
  display: block;
  padding: 1px;
  margin-top: 5px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.Assignments_assignment-div__3-1dm span.Assignments_success__1k4EF {
  background: green;
}

.Assignments_assignment-div__3-1dm span.Assignments_fail__38LCQ {
  background: red;
}

.Assignments_test-summary__2dCPh .Assignments_badge__RQWtt {
  float: right;
}

.Assignments_testSummary__1IpMc {
  background: #fff;
  width: 100%;
  float: left;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #efefef;
  box-shadow: none;
  transition: all 0.2s ease-in-out;
}

.Assignments_testSummary__1IpMc p {
  display: block;
  padding: 12px 10px;
  text-align: left;
  border-bottom: 1px dashed #efefef;
  margin: 0px;
}

.Assignments_testSummary__1IpMc p:last-child {
  padding-bottom: 5px;
  border-bottom: 0;
}

.Assignments_testSummary__1IpMc .Assignments_badge__RQWtt {
  float: right;
  padding: 6px 8px;
  font-weight: 400;
  font-size: 13px;
  width: 30px;
}

.Assignments_assignmentResult__254gt {
  display: block;
  background: transparent;
  margin-top: 8px;
  padding: 3px 10px;
  color: #fff;
  border-radius: 3px;
  text-align: center;
}

.Assignments_assignmentPass__PCkK6 {
  background: #4caf50;
}

.Assignments_assignmentPassAvg__12AvE {
  background: #ffbf00;
}

.Assignments_assignmentFail__1sxlG {
  background: #f71b1b;
}
.Assignments_assignmentMain__2sCZQ{
  margin-top: 40px;
}
.Assignments_assignmentPreviewDiv__Ys-S5 {
  background: #fff;
  width: 100%;
  border-radius: 6px;
  border: 1px solid gray;
  padding: 15px;
  box-shadow: 0 0 11px -6px rgb(187 187 187 / 65%);
  font-size: 14px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  
}

.Assignments_assignmentPreviewDiv__Ys-S5 table {
  border-collapse: separate;
  border-spacing: 0 15px;
}
.Assignments_test__10NST{
  width: 50%;
  /* border: 1px solid red !important; */
  margin: 0 auto !important;
}
.Assignments_assignmentPreviewDiv__Ys-S5 table th {
  border: 0px;
}

.Assignments_assignmentPreviewDiv__Ys-S5 table td {
  border: 0px;
}

.Assignments_assignmentPreviewDiv__Ys-S5 h2 {
  font-size: 22px;
  margin-bottom: 17px;
  font-weight: 600;
  color: #333;
  text-align: center;
}

.Assignments_testBack__14A0s {
  position: absolute;
  right: 0px;
}
.Assignments_testBack1__VucZ5{
  font-size: 16px !important;
  padding: 7px 15px !important;
    /* right: ; */
    /* margin-left: 200px; */
}

.Assignments_testStart__1EpC3 {
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.Assignments_testTimerBg__3g0xB {
  background: #fff;
  width: 100%;
  float: left;
  padding: 0;
  border-radius: 10px;
  margin-bottom: 50px;
  border: 1px solid #efefef;
  transition: all 0.2s ease-in-out;
}

.Assignments_testTimerLeft__3CIb6 {
  width: 100%;
  float: left;
  display: flex;
  margin-bottom: 0px;
}

.Assignments_testTimerLeft__3CIb6 li {
  width: 50%;
  display: inline-block;
  padding: 30px 20px;
  border-right: 1px solid #efefef;
  text-align: center;
}

.Assignments_testTimerLeft__3CIb6 h4 {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin-bottom: 15px;
  text-align: center;
  line-height: 26px;
  height: 100px;
  width: 100px;
  display: inline-block;
  border: 2px solid #333;
  border-radius: 100%;
  padding: 35px 0;
  background: #fff;
}

.Assignments_quesTitle__sPyti {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  text-align: left;
  line-height: 28px;
  margin-top: 0;
  /* margin-bottom: 14px; */
}

.Assignments_quesTitle__sPyti span {
  font-weight: 600;
  margin-right: 10px;
  float: left;
}

.Assignments_testInfo__1fqyw {
  /* text-align: right; */
  padding: 8px;
  box-shadow: 0 0.052083333in 11.25pt -9px rgb(0 0 0 / 10%);
  border-bottom: 1px solid #e6e6e6;
}
.Assignments_testInfo1__2Bkvm {
  text-align: right;
  /* padding: 8px; */
  /* box-shadow: 0 0.052083333in 11.25pt -9px rgb(0 0 0 / 10%); */
  /* border-bottom: 1px solid #e6e6e6; */
}
.Assignments_testInfo12__2GYS8 {
  text-align: right;
  padding-top: 3%;
}
.Assignments_ruleAssessment__ZNlL9{
   padding-left: 3%;
   padding-top: 2%;
}
.Assignments_startAssessment__3hxJb{
  padding-left: 4%;
}
.Assignments_imageAnswer__1o6QC{
  width: 350px;
  height:200px;
}
.Assignments_testInfo__1fqyw h3 {
  font-size: 18px;
  font-weight: 500;
  color: #333;
  margin-top: 5px;
  margin-bottom: 5px;
}

.Assignments_radioCheckbox__oMrU2 input[type="radio"] {
  margin-right: 7px;
  position: relative;
  top: 1px;
}

.Assignments_quesItem__XmMUe {
  margin-bottom: 30px;
  box-shadow: 0px 1px 13px 0px rgb(198 198 198 / 30%);
  border: 1px solid rgba(51, 122, 183, 0.21);
  padding: 10px;
  background: #fff;
}
.Assignments_note__19OqM {
  margin-bottom: 30px;
  box-shadow: 0px 1px 13px 0px rgb(198 198 198 / 30%);
  border: 1px solid rgba(51, 122, 183, 0.21);
  padding: 10px;
  background: #fff;
}
.Assignments_backLink__1LifM {
  color: #333;
  float: right;
  height: 38px;
  background: #f1f1f1;
  border: 1px solid #d0d0d0;
  border-radius: 20px;
  padding: 8px 20px;
  font-size: 14px;
  margin-top: 10px;
}

.Assignments_backLink__1LifM a {
  text-decoration: none;
  color: #000;
}

.Assignments_testResultLeft__rdaWj {
  text-align: center;
}

.Assignments_testResultLeft__rdaWj li {
  width: 150px;
  display: inline-block;
  padding: 0;
}

.Assignments_resultDt__2_ebN {
  float: left;
  width: 100%;
  text-align: center;
}

.Assignments_resultDt__2_ebN span {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 10px;
  text-align: center;
  line-height: 50px;
  height: 55px;
  width: 55px;
  display: inline-block;
  border-radius: 100%;
}

.Assignments_resultDt__2_ebN p {
  font-weight: 500;
  font-size: 16px;
}

.Assignments_rightAns__1-xdd {
  background: #01b200;
}

.Assignments_wrongAns__2JWaA {
  background: #fb3838;
}

.Assignments_totQues__2CCXL {
  background: #57a4ff;
}
.Assignments_my-component__2QGgm {
  background: #fff;
}

.Assignments_fullscreen-enabled__2JcuJ .Assignments_my-component__2QGgm {
  background: #000;
}

@media only screen and (max-width: 1750px) {

.Assignments_test__10NST{
  width: 60%;
}
}
@media only screen and (max-width: 1350px) {

.Assignments_test__10NST{
  width: 70%;
}
}
@media only screen and (max-width: 1120px) {

.Assignments_test__10NST{
  width: 80%;
}
}
@media only screen and (max-width: 992px) {

.Assignments_test__10NST{
  width: 95%;
}
}

.AssignmentType_imgDiv__31i4g{
    /* background-color: rgb(0, 0, 0); */
    /* width: 100%; */
    /* height: auto; */
    /* opacity: 0.7; */
    /* filter: brightness(30%); */

}

.Curriculum_imagetheme__3yeRU{
  background-image: url(../../static/media/group3.e7c1db50.png);
   overflow: hidden;
  padding-top: 360px;
  padding-bottom: 210px;
    background-repeat: no-repeat;
  background-position: center;
    background-size: cover;
}
@media only screen and (max-width: 1280px){
  .Curriculum_imagetheme__3yeRU{
  background-image: url(../../static/media/group3.e7c1db50.png);
   overflow: hidden;
  /* padding-top: 190px;
  padding-bottom: 210px; */
    background-repeat: no-repeat;
  background-position: center;
    background-size: cover;
}
}
/* .selectinput{
  color: black;
} */
.Curriculum_progress__2K-ZP {
  height: 10px;
  border: 1px solid #f1f1f1;
  background: #e0e0e0;
  border-radius: 10px;
}
.Curriculum_dashimg__1NzDN {
  height: 60vh;
  overflow: hidden;
}

.Curriculum_progressBar__3gVfy {
  height: 8px;
  border-radius: 10px;
}

.Curriculum_chaptersDiv__3Gjex table {
  background: #fff;
}

.Curriculum_chaptersDiv__3Gjex table td:nth-child(2) {
  font-weight: 500;
  width: 200px;
}

.Curriculum_chaptersDiv__3Gjex table td:nth-child(3) {
  width: 40%;
}

.Curriculum_modalBody__GhBIX {
  min-height: 400px;
}

.Curriculum_notAvailable__23cwN {
  text-align: center;
  width: 100%;
  padding-top: 150px;
  font-size: 18px;
  font-weight: 500;
}
.Curriculum_txt__3EJJg {
  display: flex;
  /* position: absolute; */
  align-items: center;
  width: 100%;
  height: 80px;
  left: 63px;
  top: 437px;
  font-size: 1.7rem !important;
  background: rgb(245, 240, 240);
  border-radius: 3px;
  padding: 0px 20px;
}
.Curriculum_area__9MCsp{
  display: flex;
  /* position: absolute; */
  align-items: center;
  width: 100%;
  height: 360px;
  left: 63px;
  top: 437px;
  font-size: 1.7rem !important;
  background: rgb(245, 240, 240);
  border-radius: 3px;
  padding: 0px 20px;
}
.Curriculum_area__9MCsp p{
  font-size: 12px;
}
.Curriculum_area__9MCsp input{
  font-size: 12px;
}
.Curriculum_txt__3EJJg p{
  font-size: 14px;
}

.Curriculum_box__3HgbE {
  /* box-sizing: border-box; */

  /* position: absolute; */
  width: auto;
  height: auto;
  left: 34px;
  top: 341px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
  margin-bottom: 20px;
  border-radius: 6px;
}


/* ============================================================ */
.Curriculum_curri_headings__Nm40y{
  font-size: 2.3rem !important;
    font-weight: 600;
    margin-bottom: 20px !important;
}
.Curriculum_banner__1XrBf{
  width: 100%;
}
.Curriculum_relative__2OXVc{
  position: relative !important;
}

.Curriculum_drop_icon__2d7Dy{
  position: absolute !important;
  top: 7px;
  right: 10px;
  
}
.Curriculum_banner__1XrBf img{
  background: cover !important;
  width: 100%;
}


.Curriculum_resTable__3z0B6{
  /* overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;   */
}

.Curriculum_resTable__3z0B6::-webkit-scrollbar {
  display: none;
}


@media screen and (min-width: 1200px) {
  .Curriculum_txt__3EJJg {
    width: 100%;
  }
}

@media screen and (min-width: 1100) {
  .Curriculum_txt__3EJJg {
    width: 900px;
  }
}
@media screen and (max-width: 750) {
  .Curriculum_txt__3EJJg {
    width: 200px;
  }
}

.HelpFromTutor_helpRequest__FXDrR{
  /* margin-top: 100px; */
}
.HelpFromTutor_tutorDiv__2BidA p {
  display: inline-block;
  font-size: 14px;
  color: #686f7a;
  margin-bottom: 0;
  line-height: 27px;
}

.HelpFromTutor_dashimg__upA6k {
  height: 60vh;
  overflow: hidden;
}

.HelpFromTutor_hr__2UJ0Z {
  margin-top: 15px;
  margin-bottom: 10px;
  clear: both;
}

.HelpFromTutor_curriculum__QEUr6,
.HelpFromTutor_subject__3TOVm,
.HelpFromTutor_topic__2tJ3d,
.HelpFromTutor_chapter__3zNB4 {
  float: left;
  margin-right: 10px;
  margin-top: 8px;
  font-size: 16px;
  line-height: 20px;
}

.HelpFromTutor_tutorFeatures__3wNob {
  /* background: #e9edf6; */
  background: #e9eaec;
  margin-top: 10px;
  padding: 10px;
  overflow: hidden;
}

.HelpFromTutor_tutorFeatures__3wNob span {
  cursor: pointer;
  padding: 4px 10px;
  margin-left: 10px;
  border-radius: 2px;
  float: right;
  color: #fff;
  font-size: 13px;
  letter-spacing: 0.5px;
}

.HelpFromTutor_tutorFeatures__3wNob .HelpFromTutor_replay__bBvND {
  background: #7986cb;
}

.HelpFromTutor_tutorFeatures__3wNob .HelpFromTutor_resolve__1Ye6g {
  background: #66bb6a;
}

.HelpFromTutor_tutorFeatures__3wNob .HelpFromTutor_feedback__1dg9n {
  background: #42a5f5;
}

.HelpFromTutor_raiseQueryDiv__eCUK8 select {
  height: 42px;
}

.HelpFromTutor_status__1VyGy {
  font-weight: 500;
}

.HelpFromTutor_scheduledDate__2-SQ6 {
  margin-left: 20px;
  font-weight: 500;
}

.HelpFromTutor_open__NIcX6 {
  display: block;
}

.HelpFromTutor_close__2PS0B {
  display: none;
}
.HelpFromTutor_requestQuery__1LOZ1{
  background-color: #4A1E75 !important;
  color: #fff !important;
  border-radius: 30px !important;
}
.HelpFromTutor_backrequests__3BM8B{
   background-color: #4A1E75 !important;
  color: #fff !important;
}
.HelpFromTutor_submitRequest__3MZQF{
    background-color: #4A1E75 !important;
    color: #fff !important;
    padding: 10px 15px !important;
    font-size: 16px !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd {
  height: 38px;
}
#combo-box-demo {
  position: absolute;
  width: 100%;
}

.Lab_LabDiv__2VM5W h5,
.Lab_queriesDiv__gSylZ h5 {
  font-size: 15px;
  font-weight: 500;
  color: #000000;
}

.Lab_LabDiv__2VM5W p {
  display: inline-block;
  font-size: 14px;
  color: #686f7a;
  margin-bottom: 0;
  margin-top: 3px;
}

.Lab_LabSummary__2gLjS {
  background: #fff;
  width: 100%;
  float: left;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #efefef;
  box-shadow: none;
  transition: all 0.2s ease-in-out;
}

.Lab_LabSummary__2gLjS p {
  display: block;
  padding: 12px 10px;
  text-align: left;
  border-bottom: 1px dashed #efefef;
  margin: 0px;
}

.Lab_LabSummary__2gLjS p:last-child {
  padding-bottom: 5px;
  border-bottom: 0;
}

.Lab_LabSummary__2gLjS .Lab_badge__12iCA {
  float: right;
  padding: 6px 8px;
  font-weight: 400;
  font-size: 13px;
  width: 30px;
}

.Lab_testType__otcJd {
  width: 110px;
  float: left;
  background: #fff;
  color: #0077cd;
  padding: 7px 10px;
  margin-right: 10px;
  border-radius: 3px;
  font-weight: 500;
  cursor: pointer;
}

.Lab_active__1nXUx {
  background: #0077cd;
  color: #fff;
}

.Lab_LabResult__2K_BK {
  display: block;
  background: transparent;
  margin-top: 8px;
  padding: 3px 10px;
  color: #fff;
  border-radius: 3px;
  text-align: center;
}

.Lab_labPass__3P0Fh {
  background: #4caf50;
}

.Lab_labPassAvg__1FOKn {
  background: #ffbf00;
}

.Lab_labFail__2MKdJ {
  background: #f71b1b;
}

.Sessions_sessionsDiv__2KdYR h2 {
  font-size: 15px;
  margin-bottom: 5px;
  margin-top: 0;
  color: #333;
}
.Sessions_dashimg__1ScM6 {
  /* height: 60vh;
  overflow: hidden; */
    background-image: url(../../static/media/group3.e7c1db50.png);
     overflow: hidden;
 padding-top: 320px;
  padding-bottom: 230px;
    background-repeat: no-repeat;
  background-position: center;
    background-size: cover;
}
.Sessions_styleTab__14m0j{
  border-radius: 40px;
  /* border: 2px solid red; */
}
.Sessions_sessionsDiv__2KdYR p {
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 0;
}

.Sessions_sessionsDiv__2KdYR span {
  font-size: 12px;

  padding: 5px 0;
  border-radius: 10px;
  color: #fff;
  letter-spacing: 1px;
}

.Sessions_sessionsDiv__2KdYR button {
  margin-top: 12px;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.16), 0 0px 0px 0 rgba(0, 0, 0, 0.12);
  background-color: #ea9f07;
  border-color: #ea9f07;
  width: 100%;
  padding: 5px;
  border-radius: 3px;
}

.Sessions_sessionPreviewDiv__1qKNy {
  background: #fff;
  margin-bottom: 20px;
  width: 60% !important ;
  border-radius: 6px;
  border: 1px solid gray;
  padding: 15px;
  box-shadow: 0 0 11px -6px rgb(187 187 187 / 65%);
  font-size: 14px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.Sessions_sessionPreviewDiv__1qKNy table {
  border-collapse: separate;
  border-spacing: 0 15px;
}

.Sessions_sessionPreviewDiv__1qKNy table td {
  border: 0px;
}

.Sessions_sessionPreviewDiv__1qKNy h2 {
  font-size: 22px;
  margin-bottom: 17px;
  font-weight: 600;
  color: #333;
  text-align: center;
}

.Sessions_testBack__2r1fY {
  padding: 7px 15px !important;
  font-size: 16px !important;
}
.Sessions_testBack1__1WOvV{
  position: absolute;
  right: 10px;

}

.Sessions_testStart__2SSz7 {
  position: absolute;
  right: 10px;
  bottom: 20px;
}

@media only screen and (max-width: 1100px) {
  .Sessions_sessionPreviewDiv__1qKNy{
    width: 85% !important;
  }
  }
@media only screen and (max-width: 992px) {
  .Sessions_sessionPreviewDiv__1qKNy{
    width: 95% !important;
  }
  }
.sessionForm .label-heading {
  width: 100px;
  float: left;
  position: relative;
  top: 8px;
}

.sessionForm .form-control,
.sessionForm .react-datepicker-wrapper {
  font-size: 15px !important;
  width: 70% !important;
}

.sessionForm .react-datepicker-wrapper input {
  width: 100% !important;
}

.sessionForm .error {
  margin-left: 100px;
}

.calender_dashimg__1Xm2d {
  height: 60vh;
  overflow: hidden;
}
.calender_timeWidth__ws_9c {
  width: 150px !important;
}
.calender_react-datepicker__time-container__10C5G {
  width: 150px !important;
}
.calender_relative__1IzNm{
  position: relative !important;
  *border: 1px solid red;
  width: auto !important;
}
.calender_labelStyle__1pcNU{
  font-weight: 500;
    color: #545454;
}

.calender_drop_icon__3IcUH{
  position: absolute !important;
  top: 7px;
  right: 130px;
  
}

@media screen and (max-width: 991px) {
  .calender_drop_icon__3IcUH{
    position: absolute !important;
    top: 7px;
    right: 40px;
    
  }
}

@media screen and (max-width: 575px) {
  .calender_drop_icon__3IcUH{
    position: absolute !important;
    top: 7px;
    right: 60px;
    
  }
}

@media screen and (max-width: 525px) {
  .calender_drop_icon__3IcUH{
    position: absolute !important;
    top: 7px;
    right: 40px;
    
  }
}

@media screen and (max-width: 412px) {
  .calender_drop_icon__3IcUH{
    position: absolute !important;
    top: 7px;
    right: 20px;
    
  }
}

#heading {
  color: rgb(79, 157, 202);
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.head {
  font-size: 65px;
}

.topBg{
  background: url(../../static/media/CurriculamPage.e88f8674.png);
  height: 551px;
}

#head-content {
  font-size: x-large;
  max-width: 550px;
  margin: auto;
  color: rgb(83, 83, 83);
  padding: 30px 0 !important;
}

.main {
  display: grid;
  grid-template-columns: auto auto auto auto;
  -ms-grid-column-align: center;
  *margin-top: 3%;
}

.outer {
  width: 20vw;
}

#blocks {
  display: flex;
  margin: auto;
  margin-top: 22%;
}

.block-heading {
  font-size: 20px !important;
  line-height: 24px !important;
  font-weight: 600 !important;
}

.info {
  line-height: 19px !important;
  font-size: 16px !important;
}

.amount {
  font-size: 32px !important;
  line-height: 39px !important;
  font-weight: 700 !important;
  color: #20639B !important;
  margin-top: 25px;
}

.btn2{
  margin-top: 25px;
  padding: 5px 13px;
  background: #F1E3FE;
  border: 1px solid #4A1E75;
  border-radius: 30px;
  margin-bottom: 20px;
  font-size: 20px;
  color: #464646 !important;
  font-weight: 500;
}

.btn1{
  margin-top: 25px;
  padding: 5px 13px;
  background: #4A1E75;
  border: 1px solid #4A1E75;
  border-radius: 30px;
  margin-bottom: 20px;
  font-size: 20px;
  color: white !important;
  font-weight: 500;
}

.block {
  width: 35vw;
  *height: 30vh;
  padding: 25px 10px;
  background-color: #C5F9D5;
  border-radius: 8px;
}



#button {
  width: 100%;
  color: red;
  /* margin: auto; */
}

@media only screen and (max-width: 1064px) {
  .main {
    display: grid;
    grid-template-columns: auto auto auto ;
  
  }
  .outer {
    width: 25vw;
   
  }
}

@media only screen and (max-width: 912px) {
  .outer {
    width: 30vw;
   
  }

  .block-heading {
    font-size: 20px !important;
    line-height: 24px !important;
    font-weight: 600 !important;
  }

  .info {
    line-height: 19px !important;
    font-size: 16px !important;
  }

  .amount {
    font-size: 32px !important;
    line-height: 39px !important;
    font-weight: 700 !important;
  }
}

@media only screen and (max-width: 836px) {
  .main {
    display: grid;
    grid-template-columns: auto auto ;
    *border: 1px solid red;
    margin: 0 auto;
   
  }
  .outer {
    width: 40vw;
    *border: 1px solid red;
  }
  .block {
    width: 40vw;
  }
}

@media only screen and (max-width: 605px) {
  .main {
   display: flex;
   flex-direction: column; 
  }
  .outer {
    width: 75vw;
    *border: 1px solid red;
  }
  .block {
    width: 75vw;
  }
}

.box_img {
  /* margin: 0 auto !important; */
  height: 60px;
  width: 60px;
}


#heading {
  color: rgb(79, 157, 202);
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.head {
  font-size: 65px;
}

.topBg{
  background: url(../../static/media/CurriculamPage.e88f8674.png);
  height: 551px;
}

#head-content {
  font-size: x-large;
  max-width: 550px;
  margin: auto;
  color: rgb(83, 83, 83);
  padding: 30px 0 !important;
}

.main {
  display: grid;
  grid-template-columns: auto auto auto auto;
  -ms-grid-column-align: center;
  *margin-top: 3%;
}

.outer {
  width: 20vw;
}

#blocks {
  display: flex;
  margin: auto;
  margin-top: 22%;
}

.block-heading {
  font-size: 20px !important;
  line-height: 24px !important;
  font-weight: 600 !important;
}

.info {
  line-height: 19px !important;
  font-size: 16px !important;
}

.amount {
  font-size: 32px !important;
  line-height: 39px !important;
  font-weight: 700 !important;
  color: #20639B !important;
  margin-top: 25px;
}

.btn2{
  margin-top: 25px;
  padding: 5px 13px;
  background: #F1E3FE;
  border: 1px solid #4A1E75;
  border-radius: 30px;
  margin-bottom: 20px;
  font-size: 20px;
  color: #464646 !important;
  font-weight: 500;
}

.btn1{
  margin-top: 25px;
  padding: 5px 13px;
  background: #4A1E75;
  border: 1px solid #4A1E75;
  border-radius: 30px;
  margin-bottom: 20px;
  font-size: 20px;
  color: white !important;
  font-weight: 500;
}

.block {
  width: 35vw;
  *height: 30vh;
  padding: 25px 10px;
  background-color: #C5F9D5;
  border-radius: 8px;
}



#button {
  width: 100%;
  color: red;
  /* margin: auto; */
}

@media only screen and (max-width: 1064px) {
  .main {
    display: grid;
    grid-template-columns: auto auto auto ;
  
  }
  .outer {
    width: 25vw;
   
  }
}

@media only screen and (max-width: 912px) {
  .outer {
    width: 30vw;
   
  }

  .block-heading {
    font-size: 20px !important;
    line-height: 24px !important;
    font-weight: 600 !important;
  }

  .info {
    line-height: 19px !important;
    font-size: 16px !important;
  }

  .amount {
    font-size: 32px !important;
    line-height: 39px !important;
    font-weight: 700 !important;
  }
}

@media only screen and (max-width: 836px) {
  .main {
    display: grid;
    grid-template-columns: auto auto ;
    *border: 1px solid red;
    margin: 0 auto;
   
  }
  .outer {
    width: 40vw;
    *border: 1px solid red;
  }
  .block {
    width: 40vw;
  }
}

@media only screen and (max-width: 605px) {
  .main {
   display: flex;
   flex-direction: column; 
  }
  .outer {
    width: 75vw;
    *border: 1px solid red;
  }
  .block {
    width: 75vw;
  }
}

.box_img {
  /* margin: 0 auto !important; */
  height: 60px;
  width: 60px;
}


.Document_dashimg__2ldKs {
  /* height: 60vh;
    overflow: hidden; */
  background-image: url(../../static/media/group3.e7c1db50.png);
  overflow: hidden;
  padding-top: 320px;
  padding-bottom: 230px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.Document_documentRow__12Jqv {
  height: 200px;
}

.Document_tabItem__kSwHX {
  color: #222648;

  float: left;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  border: none;
  padding: 5px 7px;
  margin-right: 10px;
  border-radius: 10px !important;
  cursor: pointer;

  width: auto;

  background: #4a1e75;
}

.Topbar_navbar1__3ltXd{
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 75px;
  background: #fff;
  display: flex !important;
  align-items: center;
  box-shadow: 0 0.052083333in 11.25pt -9px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #e6e6e6;
  /* margin-bottom: 10px; */
}

.Topbar_dropdownMenu__3DmMQ {
  right: 0px !important;
  left: auto !important;
  width: 400px;
 min-height: auto !important;
 height: auto !important;
 max-height: 270px !important;
}
.Topbar_dropdownMenu__3DmMQ::-webkit-scrollbar{
  display:none;
}
.Topbar_dropdownMenu1__NZCWn {
  right: 0px !important;
  left: auto !important;
  /* width: 500px; */
}
.Topbar_logo__1O_ea{
  border: 2px solid blue;
}
/* .active{
  background-color: #e6e6e6 !important;
} */
.Topbar_navbarNav1__2F_aO{
  display: flex;
  justify-content: space-between;
  /* width: 70%; */
}
.Topbar_navbarNav1__2F_aO i {
  font-size: 30px;
  background-color: none;
}
.Topbar_dd__33G1j ul li{
  font-size: 18px;
  /* width: 140px; */
  line-height: 40px;
  padding-right: 20px;
  text-align: center;
  /* border: 1px solid red; */
}
.Topbar_navbarNav1__2F_aO li:hover{
  background-color: lightgray;
  /* padding: 2px; */
}
.Topbar_map__1BF1B {
  height: 10px;
}
.Topbar_flat__O7zUl{
  background-color:red
}
.Topbar_studentNotification__1KoU1{
   padding-left: 2%;
   position: relative;
}
.Topbar_iconText__caqCf{
  /* display:flex; */
  align-items: center;
  /* border: 1px solid red; */
}
.Topbar_iconText__caqCf:hover{
  background: rgb(247, 247, 247);
}
.cardDiv {
  /* background: f5f5f5; */
  margin-bottom: 10px;
  float: left;
  width: 100%;
  border-radius: 6px;
  border: 4px solid #eee;
  padding: 15px;
}
.cardDivtop {
  /* background-color: rgb(255, 136, 0); */
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  /* align-content: space-around; */
  /* margin-top: 80px;
  margin-left: 40px; */
}
.home {
  /* background-color: aqua; */
  display: flex;
  margin-right: 65px;
}

.cardDivAss {
  /* background: #fff; */
  /* margin-bottom: 20px; */
  /* float: left; */
  /* width: 209px; */
  /* border-radius: 29px; */
  /* border: 2px solid #eee; */
  /* padding: 15px; */
  /* height: 275px; */
  /* background-color: #ffa04d; */
}

.cardDivSess {
  /* background: #fff;
  margin-bottom: 20px;
  float: left;
  width: 209px;
  border-radius: 29px;
  border: 2px solid #eee;
  padding: 15px;
  height: 275px;
  background: #0747a6; */
}

.cardDivMy {
  /* background: #fff;
  margin-bottom: 20px;
  float: left;
  width: 188px;
  height: 153px;
  border-radius: 29px;
  border: 2px solid #eee;
  padding: 15px;
  background: #fdda5f; */
}
/* h4::before {
  content: "---";
  background-color: #c182f9;
  color: #c182f9;
  font-weight: bold;
  border-radius: 100%;
} */

.cardDiv1 {
  /* background-color: #ECF3FF;; */
  width: 1250px;
  height: 244px;
  top: 437px;
  left: 67px;
  radius: 6px;
  margin: 15px;
  border-radius: 6px;
}
@media only screen and (max-width: 1300px) {
  .cardDiv1 {
    width: 1180px;
    /* height: 244px;
  top: 437px;
  left: 67px;
  radius: 6px;
  margin: 15px;
  border-radius: 6px; */
  }
}

.header_navbar__NqUjg {
  position: fixed;
  /* z-index: 999; */
  width: 100%;
  height: 60px;
  background: #fff;
  display: flex !important;
  align-items: center;
  box-shadow: 0 0.052083333in 11.25pt -9px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #e6e6e6;
}
.header_navDashboard__R3fay{
   font-family: roboto !important;
   /* text-transform: capitalize; */
}

.header_dropdownMenu__1s-JQ {
  right: 0px !important;
  left: auto !important;
  width: 400px;
  min-height: auto !important;
  height: auto !important;
  max-height: 270px !important;
}
.header_dropdownMenu__1s-JQ::-webkit-scrollbar{
  display:none;
}
.header_dropdownMenu1__1FERp {
  right: 0px !important;
  left: auto !important;
  /* width: 500px; */
}

.header_navbarNav__mY11I i {
  font-size: 25px;
}
.header_rejectAdmin__1od0f{
  padding-left: 2%;
  position: relative;
}
.header_notificationnn__3MbDC:hover{
  background: rgb(247, 247, 247);
}
.nav-link.active {
  color: #222 !important;
  background-color: #fff !important;
}
.nav-link{
  font-size: 16px !important;
  font-family: roboto !important;
  /* color: #fff !important; */
}
/* .nav-link.active1 {
  background-color: blue;
} */
.sidebar_navbarHeader__JrG_X {
  padding: 3px 5px;
  background: #4A1D75;
}

.sidebar_navbarBrand__2T4-D {
  font-size: 22px !important;
  font-weight: 600 !important;
  letter-spacing: 1px !important;
    font-family: roboto !important;
}

.sidebar_mainSidebar__34hH7 {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 240px;
  z-index: 1035;
  transition: width 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  background: #4A1D75;
  /* border: 1px solid red; */
  /* box-shadow: rgba(146, 153, 184, 0.063) 0px 0px 30px; */
  overflow-y: auto;
}

.sidebar_mainSidebar__34hH7 ul {
  padding-left: 15px;
  position: absolute;
  width: 100%;
  height: 100%;
  padding-left: 0px;
}

.sidebar_mainSidebar__34hH7 li {
  padding: 2px 0px;
  cursor: pointer;
  text-align: left;
}

.sidebar_mainSidebar__34hH7 a {
  color: #fff;
  font-size: 14px;
  text-decoration: none;
  font-weight: 400;
  padding: 10px 15px !important;
}

.sidebar_sidebaricons__2CiVx {
  color: #dadada;
  margin-right: 10px;
  font-size: 20px !important;
  margin-right: 6px;
  position: relative;
  top: 3px;
  width: 30px;
}
.sidebar_nav-link__2XwrL.sidebar_active__KvtBD {
  color: #333 !important;
  background-color: #fff !important;
}

.logo {
  height: 200px;
  width: 200px;
  border: 3px dotted #000;
  padding: 5px;
}
.imageLogo {
  height: 200px;
  width: 200px;
}
.input-group-text {
  width: 30% !important;
  border-radius: 40px;
}
.CurriculamHeader {
  display: flex;
  justify-content: space-between;
  width: 98% !important;
  margin-bottom: 15px !important;
  align-items: center;
  /* padding: 15px; */
}
.text-align {
  font-size: 18px;
  font-weight: 500;
}
@media only screen and (max-width: 1280px) {
  .CurriculamHeader {
    width: 84% !important;
  }
}
.btn btn-sm add-btn {
  background-color: #4a1d75 !important;
}
body {
  margin: 30px;
}
.search {
  position: relative;
  color: #aaa;
  font-size: 14px;
}

.search {
  display: inline-block;
}

.search input {
  width: 230px;
  height: 35px;

  background: #fcfcfc;
  border: 1px solid #aaa;
  border-radius: 5px;
  box-shadow: 0 0 3px #ccc, 0 10px 15px #ebebeb inset;
}

.search input {
  text-indent: 32px;
}
.search .fa-search {
  position: absolute;
  top: 10px;
  left: 10px;
}

/* .search .fa-search {left: auto; right: 10px;} */

.table_container table thead tr th {
  background-color: #f1e3fe !important;
  padding-bottom: 16px !important;
}
.maindiv {
  height: 80vh !important;
  overflow: scroll !important;
  border: 1px solid red;
  visibility: visible;
}
.uploadButton {
  background: #4a1d75;
  color: white;
  padding: 5px 9px;
  border-radius: 5px;
}
.uploadButtonChange {
  background: whitesmoke;
  color: black;
  border-color: #4a1d75;
  padding: 5px;
  border-radius: 5px;
}
@media (max-width: 650px) {
  .searchInput {
    width: 140px !important;
  }
}

.error {
  border: 1px solid red;
}

.courseHeader{
    display: flex;
  justify-content: space-between;
  width: 98% !important;
  margin-bottom: 15px !important;
  align-items: center;
}
.input-group-text{
    width: 30% !important;
  border-radius: 40px !important;
}
@media only screen and (max-width: 1280px){
  .courseHeader{
    width: 85% !important;
  }
}
/* body { margin: 30px; } */
.search {
  position: relative;
  color: #aaa;
  font-size: 5px;
}

.search {display: inline-block;}

.search input {
  width: 230px;
  height: 35px;

  background: #fcfcfc;
  border: 1px solid #aaa;
  border-radius: 5px;
  box-shadow: 0 0 3px #ccc, 0 10px 15px #ebebeb inset;
}

.search input { text-indent: 32px;}
.search .fa-search { 
  position: absolute;
  top: 10px;
  left: 10px;
}


.table_container table thead tr th{
  color: #20639B !important;
  background-color: #F1E3FE !important;
  padding-bottom:16px !important;
}
.table_container1 table thead tr th{
background-color: #F1E3FE !important;
padding-bottom:16px !important;
}
.subjectHeader{
     display: flex;
  justify-content: space-between;
  width: 98% !important;
   margin-bottom: 15px !important;
  align-items: center;
}
.input-group-text{
  width: 40% !important;
  border-radius: 40px;
}
body { margin: 30px; }
.search {
  position: relative;
  color: #aaa;
  font-size: 14px;
}

.search {display: inline-block;}

.search input {
  width: 230px;
  height: 35px;

  background: #fcfcfc;
  border: 1px solid #aaa;
  border-radius: 5px;
  box-shadow: 0 0 3px #ccc, 0 10px 15px #ebebeb inset;
}

.search input { text-indent: 32px;}
.search .fa-search { 
  position: absolute;
  top: 10px;
  left: 10px;
}
.table_container table thead tr th{
    background-color: #F1E3FE !important;
  padding-bottom:16px !important;
}

.relative{
  position: relative !important;
  *border: 1px solid red;
  width: auto !important;
}

.drop_icon{
  position: absolute !important;
  top: 30px;
  right: 10px;
  
}
#combo-box-demo {
  position: absolute;
  width: 81%;
  overflow: hidden;
}
.input-group-text{
   width: 30% !important;
  border-radius: 40px;
}
.questionHeader{
  display: flex;
  justify-content: space-between;
  width: 98% !important;
  margin-bottom: 15px !important;
  align-items: center;
}
@media only screen and (max-width: 1280px){
  .questionHeader{
     width: 85% !important;
  }
}
.table_container table thead tr th{
  color: #20639B !important;
  background-color: #F1E3FE !important;
  padding-bottom:16px !important;
}

.relative{
  position: relative !important;
  *border: 1px solid red;
  width: auto !important;
}

.drop_icon{
  position: absolute !important;
  top: 32px;
  right: 10px;
  
}
.templateHeader {
  display: flex;
  justify-content: space-between;
  width: 98% !important;
  margin-bottom: 15px !important;
  align-items: center;
}
.input-group-text {
  width: 30% !important;
  border-radius: 40px;
}
@media only screen and (max-width: 1280px) {
  .templateHeader {
  }
}
.table_container table thead tr th {
  background-color: #f1e3fe !important;
  padding-bottom: 16px !important;
}
.autocompl {
  padding: 30px !important;
}

.relative{
  position: relative !important;
  *border: 1px solid red;
  width: auto !important;
}

.drop_icon{
  position: absolute !important;
  top: 32px;
  right: 10px;
  
}
.assessmentHeader{
   display: flex;
  justify-content: space-between;
  width: 98% !important;
  margin-bottom: 15px !important;
  align-items: center;
}
#time-input::-webkit-calendar-picker-indicator {
  background: none;
  display: none;
}
/* .without_ampm::-webkit-datetime-edit-ampm-field {
  display: none;
} */
.in_put{
  background-color: white;
  display: inline-flex;
  border: 1px solid #ccc;
  color: #555;
  width :90px;
   
  }
@media only screen and (max-width: 1280px){
   .assessmentHeader{
  width: 85% !important;
}
}
body { margin: 30px; }
.search {
  position: relative;
  color: #aaa;
  font-size: 14px;
}

.search {display: inline-block;}

.search input {
  width: 230px;
  height: 35px;

  background: #fcfcfc;
  border: 1px solid #aaa;
  border-radius: 5px;
  box-shadow: 0 0 3px #ccc, 0 10px 15px #ebebeb inset;
}

.search input { text-indent: 32px;}
.search .fa-search { 
  position: absolute;
  top: 10px;
  left: 10px;
}
.staffHewader{
  display: flex;
  justify-content: space-between;
  width: 98% !important;
  margin-bottom: 15px !important;
  align-items: center;
  margin-top: 10px !important;
}
@media only screen and (max-width: 1280px){
    .staffHewader{
  width: 74% !important;
}
}
body { margin: 30px; }
.search {
  position: relative;
  color: #aaa;
  font-size: 14px;
}

.search {display: inline-block;}

.search input {
  width: 230px;
  height: 35px;

  background: #fcfcfc;
  border: 1px solid #aaa;
  border-radius: 5px;
  box-shadow: 0 0 3px #ccc, 0 10px 15px #ebebeb inset;
}

.search input { text-indent: 32px;}
.search .fa-search { 
  position: absolute;
  top: 10px;
  left: 10px;
}

.relative{
  position: relative !important;
 
  width: auto !important;
}

.drop_icon{
  position: absolute !important;
  top: 32px;
  right: 10px;
}
body { margin: 30px; }
.search {
  position: relative;
  color: #aaa;
  font-size: 14px;
}

.search {display: inline-block;}

.search input {
  width: 230px;
  height: 35px;

  background: #fcfcfc;
  border: 1px solid #aaa;
  border-radius: 5px;
  box-shadow: 0 0 3px #ccc, 0 10px 15px #ebebeb inset;
}

.search input { text-indent: 32px;}
.search .fa-search { 
  position: absolute;
  top: 10px;
  left: 10px;
}
.videoHeader{
  display: flex;
  justify-content: space-between;
  width: 98% !important;
  margin-bottom: 15px !important;
  align-items: center;
}

/* width */
.scroll-div::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
.scroll-div::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.scroll-div::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.scroll-div::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.overflowList{
  overflow: auto !important;
  height: 70vh !important;
}
body { margin: 30px; }
.search {
  position: relative;
  color: #aaa;
  font-size: 14px;
  margin-bottom: 10px;
}

.search {display: inline-block;}

.search input {
  width: 230px;
  height: 35px;

  background: #fcfcfc;
  border: 1px solid #aaa;
  border-radius: 5px;
  box-shadow: 0 0 3px #ccc, 0 10px 15px #ebebeb inset;
}

.search input { text-indent: 32px;}
.search .fa-search { 
  position: absolute;
  top: 10px;
  left: 10px;
}

.select-box{
  width: 100%;
  padding: 8px 10px;
  border: 1px solid lightgray;
  border-radius: 5px;
}

.text-gray{
  color: rgb(110, 107, 107);
}
.teacherSession_date__1fq8W {
  background-color: #4A1E75;
  border-radius: 5px;
  padding: 3px 15px;
  color: rgb(255, 255, 255);
}

.teacherSession_head__WHG5A {
  font-size: 18px;
}
.teacherSession_amount__2Q7ec{
  font-size: 12px !important;
  font-weight: 700;
}
.teacherSession_num__2SQEP {
  font-size: 23px;
  text-align: center;
}
.teacherSession_click__3pwO8{
  margin: 3px;
}
.teacherSession_join__mz8jt{
  background: #EBD8FE !important;
  color:  #4A1E75 !important;
  padding: 5px 30px !important;
}
.teacherSession_join__mz8jt:hover{
  background-color: #e0c4fc !important;
}
.teacherSession_cardheading__150uS{
  padding: 17px 17px;
  background: #F1E3FE;
  border-radius: 10px;
  color:#20639B;
}
.adminSession_date__3fPx_ {
  background-color: #4a1d75;
  border-radius: 50px;
  padding: 3px 20px;
  color: rgb(255, 255, 255);
}
.adminSession_labelStyle__kP6LF{
  font-weight: 500;
    color: #545454;
}
.adminSession_head__nwHeN {
  font-size: 18px;
}
.adminSession_num__1u2EC {
  font-size: 23px;
  text-align: center;
}
.adminSession_click2__1Joxd {
  height: 30px;
  margin: 3px;
  /* padding: 50px; */
  background-color: #e0f6f6 !important;
  color: #1db9aa !important;
  border: 2px solid #e0f6f6;
}
.adminSession_click1__2yv48 {
  height: 30px;
  margin: 3px;
  /* padding: 50px; */
  background-color: #cbb4b4 !important;
  color: red !important;
  border: 2px solid #cbb4b4;
}
.adminSession_acceptedTecher__1myib {
  max-height: 150px;
  overflow: scroll;
}

.adminSession_teacherLi__1aRzX {
  display: flex;
  flex-direction: row;
  grid-gap: 30px;
  gap: 30px;
  background-color: red;
}
.adminSession_adminSession__1BMYN {
  color: #20639b;
}
.adminSession_sessionButton__2cul7 {
  /* border: 2px solid red; */
}
@media (max-width: 1126px) {
  .adminSession_sessionButton__2cul7 {
    /* flex-direction: column ;
    align-items: center; */
    display: flex;
    flex-direction: row;
    height: 60px;
    /* justify-content: space-between; */
  }
  .adminSession_date__3fPx_ {
    width: 120px !important;
  }
  .adminSession_dateResponsive__17okp {
    display: flex;
    justify-content: space-between;
  }
  .adminSession_head__nwHeN{
    font-size: 14px;
  }
}
@media (max-width: 900px) {
  .adminSession_mainAdminSession__1VnXo {
    flex-direction: column;
    /* text-align: center; */
    /* align-items: center; */
  }
  .adminSession_dateSession__3fpzx {
    width: 400px !important;
  }
  .adminSession_TotalSesion__Lkzjb {
    width: 400px !important;
    margin-top: 30px !important;
  }
  .adminSession_sessionButton__2cul7 {
    width: 400px !important;
    margin-top: 30px !important;
    align-items: center;
  }
  .adminSession_date__3fPx_ {
    width: 150px !important;
  }
  .adminSession_dateResponsive__17okp {
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width:606px){
  .adminSession_date__3fPx_{
    width: 120px !important;
  }
}

.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4D4D4D;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: #19AB27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #128D15;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4D4D4D;
  border-radius: 50%;
  background-color: #FAFAFA;
  box-sizing: border-box;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #19AB27;
}

.react-toggle--focus .react-toggle-thumb {
  box-shadow: 0px 0px 2px 3px #0099E0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  box-shadow: 0px 0px 5px 5px #0099E0;
}

.record_progress__1-Zx_ {
  height: 10px;
  border: 1px solid #f1f1f1;
  background: #e0e0e0;
  border-radius: 10px;
}

.record_progressBar__3T5Y3 {
  height: 8px;
  border-radius: 10px;
}

.record_chaptersDiv__pD8-e table {
  background: #fff;
}

.record_chaptersDiv__pD8-e table td:nth-child(2) {
  font-weight: 500;
  width: 200px;
}

.record_chaptersDiv__pD8-e table td:nth-child(3) {
  width: 40%;
}

.record_modalBody__tMfXl {
  min-height: 400px;
}

.record_notAvailable__2z5kI {
  text-align: center;
  width: 100%;
  padding-top: 150px;
  font-size: 18px;
  font-weight: 500;
}

/* body { margin: 30px; } */
.search {
  position: relative;
  color: #aaa;
  font-size: 14px;
  margin-bottom: 10px !important;
}

.search {display: inline-block;}

.search input {
  width: 230px;
  height: 35px;

  background: #fcfcfc;
  border: 1px solid #aaa;
  border-radius: 5px;
  box-shadow: 0 0 3px #ccc, 0 10px 15px #ebebeb inset;
}

.search input { text-indent: 32px;}
.search .fa-search { 
  position: absolute;
  top: 10px;
  left: 10px;
}


.table_container2 table thead tr th{
    background-color: #F1E3FE !important;
  padding-bottom:18px !important;
}
.Admin_admindashome__21B00 {
  margin-left: 60px;
}
.Admin_main__Gx9FO {
  display: flex;
  justify-content: space-around;
}
.Admin_card__32FP2 {
  margin: 25px;
  padding: 20px;
  width: 272px;
  height: 142px;

  border-radius: 8px;
  background: #a9dddd;
}
.Admin_curri__3wrdv {
  display: flex;
  justify-content: space-between;
}
.Admin_card2__38V2H {
  margin: 25px;
  padding: 20px;

  width: 272px;
  height: 142px;
  border-radius: 8px;
  background: #fbb0b8;
}
.Admin_assess__1PILu {
  display: flex;
  justify-content: space-between;
}
.Admin_card3__2A9ZG {
  margin: 25px;
  width: 272px;
  height: 142px;
  padding: 20px;
  border-radius: 8px;
  background: #ccbfeb;
}
.Admin_sessio__1UFwT {
  display: flex;
  justify-content: space-between;
}
.Admin_submain__1yZPM {
  display: flex;
  justify-content: space-around;
  /* padding: 0 25%; */
  padding-top: 5%;
  padding-left: 19%;
  padding-right: 15%;
}
.Admin_card4__1IznJ {
  margin: 25px;
  width: 272px;
  height: 142px;
  padding: 20px;
  border-radius: 8px;
  background: #ffd59c;
}
.Admin_student__1C2wW {
  display: flex;
  justify-content: space-between;
}
.Admin_card5__2T5sR {
  margin: 25px;
  width: 272px;
  height: 142px;
  padding: 20px;
  border-radius: 8px;
  background: #a9dbff;
}
.Admin_teacher__1DJGa {
  display: flex;
  justify-content: space-between;
}
.Admin_fontDesign__3dcSa{
    font-family: roboto !important;
}
@media (max-width:1230px){
.Admin_admindashome__21B00 {
  margin-left: 2px;
}
.Admin_comanCard__2_Nao{
  width: 200px;
}
}
@media (max-width:940px){
.Admin_main__Gx9FO {
   flex-direction: column;
    align-items: center;
}
.Admin_comanCard__2_Nao{
  width: 400px;
}
.Admin_submain__1yZPM{
  flex-direction: column;
    align-items: center;
    padding: 0;
}
}

*{
    
    padding: 0;
    margin: 0;
    /* align-items: center; */
}
.Addnotification_content{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
   
}
.Addnotification_content h1{
    /* margin-top: 100px; */
    font-size: 24px;
    font-weight: 600;
    color: #501a74;
}
.notification-head{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    width: 89%;
    align-items: flex-end;
    margin-bottom: 10px;
}
.notification_container{
    border: 1px solid grey;
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;
    width: 90%;
    margin-top: 10px;
    background-color: #fff;
    /* border-radius: 10px; */
    /* font-size: 24px; */
    line-height: 32px;
    color: #4a1d75;
}
.notification_inputs{
    width: 90%;
    height: 100%;
    border-radius: 10px;
   /* align-items: center;
   justify-content: center;
   display: flex;
    flex-direction: column; */
   
    margin: 20px 0px;
    
}
 /* input::placeholder{
font-size: 18px;
} */
.notification_inputs textarea{
    cursor: pointer;
}
.submit_btn{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    
}
.cancel_btn #cancel_btn{
border-radius: 40px;
background-color:  #4a1d75;
border: none;
width: 140px;
text-align: center;
padding: 6px;
color: #fff;
height: auto;

}
.submit_btn #submit_btn{
    
 
    /* font-size: 24px;
padding: 10px 25px 18px 25px;
width:230px;
text-align: center;
align-items: center;
height: 60px;
border-radius: 10px;
background-color: #4a1d75;
color: #fff; */

    border-radius: 40px;
    background-color:  #4a1d75;
    border: none; 
    width: 140px;
    text-align: center;
    /* padding: 6px; */
    color: #fff;
    height: auto;
}



/* table */
.notification_table-top{
    width: 100%;
    height: 100px;
    display: flex;
    
    /* align-items: center; */
    justify-content: center;
}
.head_table{
    width: 50%;
    display: flex;
    align-items: end;
    text-align: left;
    justify-content: left;
    padding: 0px 20px;
}
.btn_table{
    width: 50%;
    display: flex;
    align-items: end;
    padding: 0px 20px;
    justify-content: flex-end;
}
.btn_table #add_btn{

    /* font-size: 24px;
padding: 5px 25px 18px 25px;
width:230px;
align-items: center;

height: 50px;
border-radius: 10px;
background-color: #4a1d75;
color: #fff; */

border-radius: 40px;
background-color: rgb(74, 29, 117);
border: 2px solid rgb(74, 29, 117);
width: 140px;
text-align: center;
padding: 6px;
color: white;
height: auto;
}
.head_table H1{
    padding: 10px;
    color: #501a74;
    font-size: 24px;
    font-weight: 600
    
}
.table_container {
     width: 100%;
     padding: 20px;
}
.notification_table table{
    width: 100%;
    padding: 20px 40px !important;
}
.notification_table .table th {
    color: rgb(32, 99, 155);
}
.notification_table table thead tr th{
    background-color:  #f1e3fe;
    color: #4a1d75;
}
.del_btn{
    border: none;
    width: 60px;
    height: 27px;
    background-color: rgb(228, 152, 152);
}






.crud-btn1 {
  background-color: rgb(255, 231, 168);
  color: rgb(234, 183, 49);
  padding: 5px 5px;
  font-size: 11px;
  text-decoration: none;
  border-radius: 3px;
  font-weight: 500;
}
.crud-btn2 {
  background-color: rgb(222, 242, 233);
  color: #49af48;
  font-size: 11px;
  padding: 5px 5px;
  text-decoration: none;
  border-radius: 3px;
  font-weight: 500;
}
.crud-btn3 {
  background-color: rgb(228, 152, 152);
  color: #ed2d2d;
  padding: 5px 5px;
  font-size: 11px;
  text-decoration: none;
  border-radius: 3px;
  font-weight: 500;
}
.search-con {
  position: relative !important;

  padding: 0 20px !important;
}

.icon123 {
  position: absolute !important;
  top: 0;
  left: 25px;
  padding-top: 13px;
}

.container23 {
  background-color: white;
  margin: 0 25px !important;
}

#search-input {
  border-radius: 50px;
}

.add-facalities-div {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  margin-top: 5px;
}

.add-input-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid black;
  padding: 5px 10px;
  margin-bottom: 5px;
  border-radius: 5px;
  width: 100%;
  margin-top: 10px;
}

.add-btn {
  padding: 7px 15px;
  border-radius: 6px;
  background-color: rgb(74, 29, 117);
  color: white;
  border: none;
}

.custom-css-add {
  border-radius: 6px !important;
}

*{
  font-family: "Roboto";
}
.form-heading {
  font-size: 23px;
  text-align: left;
 /*  margin-bottom: 15px;
  margin-top: 15px; */
  font-weight: 600;
  width: 100%;
  padding: 15px 22px;
}
.session_heading h1{
  font-size: 23px;
  text-align: left;
  color: #fff;
 /*  margin-bottom: 15px;
  margin-top: 15px; */
  font-weight: 600;
  width: 100%;
  padding: 15px 22px;
  background:  #4A1E75 !important;
  border-radius: 5px !important;
}

.assessment_heading .form-heading{
  width: 100% !important;
  padding: 10px 20px;
  background: #4A1E75;
  color: #ffffff;
  font-size: 20px;
  border-radius: 5px;
}

.calender_heading .form-heading{
  background: #4A1E75 !important;
  color: #ffffff !important;
  padding: 10px 20px;
  border-radius: 5px;
}

.ck-content {
  min-height: 150px;
  height: 150px !important;
  overflow-y: auto !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.add-btn-div {
  width: 100%;
  border-radius: 40px;
  /* padding: 15px 0; */
  /* margin-top: -60px; */
  /* text-align: end; */
  
}

.add-btn {
  float: right;
}
.add-btn-1{
  float: left; 
}

.text-view {
  width: 100%;
  border: 1px solid #efecec;
  min-height: 50px;
  vertical-align: middle;
  border-bottom: 0px;
  padding: 12px;
  display: flex;
}

.text-view:last-child {
  border-bottom: 1px solid #efecec;
}

.text-view label {
  font-weight: 500;
  color: #908e8e;
  display: block;
  margin: 2px;
  font-size: 14px;
  float: left;
  width: 35%;
}

.text-view span {
  font-weight: 400;
  font-size: 15px;
  width: 65%;
}

.text-view-long span {
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
}

.closePage {
  clear: both;
  width: 100%;
  background: #ffffff;
  padding: 10px 15px;
  margin-bottom: 20px;
  border-bottom: 1px solid #dcdcdc;
  /* margin-top: -18px; */
}

.closePage h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
  margin-top: 5px;
  float: left;
}

.closePage .btn {
  float: right;
  margin-top: 2px;
  width: 100px;
  letter-spacing: 1px;
  font-weight: 500;
}

.table {
  margin-bottom: 0px;
}

.table > thead > tr {
  background-color: #ececec !important;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 0px;
}

.table td {
  vertical-align: middle !important;
}

/* .table td:last-child {
  width: 150px;
} */

.table thead th {
  vertical-align: bottom;
  border-bottom: 0px solid #dee2e6;
  color: #20639B;
  background: white;  
  font-weight: 600;
  font-size: 15px;
}

.question-list {
  padding: 20px;
  line-height: 30px;
}

.la-check-circle {
  color: green;
  font-size: 20px;
  position: relative;
  top: 2px;
}

i.las.la-times-circle {
  color: red;
  font-size: 30px;
  position: relative;
  top: 2px;
}
.question-list .ans {
  margin-bottom: 5px;
}

.form-control {
  font-size: 20px !important;
}

.staffSelection {
  position: relative;
  top: 40px;
  left: 15px;
}

.eventModal .text-view label {
  width: 170px;
  color: #000;
}

.eventModal .text-view {
  width: 100%;
  border: 0px solid #efecec;
  min-height: 30px;
  vertical-align: middle;
  border-bottom: 0px;
  padding: 7px;
}

.eventModal .text-view span {
  font-weight: 400;
  font-size: 14px;
}

.eventModal .text-view ol {
  margin-left: 150px;
  margin-bottom: 0px;
}

.eventModal .modal-title {
  font-size: 18px;
}

/* 
* {
  box-sizing: border-box;
}
.topCardLeft h5 {
  font-size: 14px;
  margin-bottom: 12px;
  text-align: left;
  color: #9c9c9c;
  font-weight: 400;
}

.topCardLeft h2 {
  font-size: 17px;
  margin-bottom: 10px;
  margin-top: 0;
}

.topCardLeft span {
  font-size: 12px;
  background: #0079d2;
  padding: 5px 10px;
  border-radius: 10px;
  color: #fff;
  letter-spacing: 1px;
}


.topCardRight {
  margin-left: 80px;
  display: flex;
  justify-content: space-around;
  align-content: space-around;
}

.topCardRight img {
  width: 58px;
}


.queriesDiv h5 {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-bottom: 5px;
}
.assignmentDiv1 h5 {
  margin-top: 45px !important;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-bottom: 5px;
}

.assignmentDiv p {
  display: inline-block;
  font-size: 14px;
  color: #686f7a;
  margin-bottom: 0;
  margin-top: 3px;
}

.queriesDiv p {
  display: inline-block;
  font-size: 14px;
  color: #686f7a;
  margin-bottom: 0;
  line-height: 27px;
}

.hr {
  margin-top: 10px;
  margin-bottom: 10px;
}

.curriculum {
  margin-right: 10px;
  font-size: 15px;
}

.subject,
.chapter {
  float: left;
  margin-right: 10px;
  font-size: 15px;
}

.queryDiv {
  line-height: 28px;
}

.queryDiv .subject {
  float: none;
  margin-right: 10px;
  font-size: 14px;
  width: 100%;
}

.queryDiv .topic {
  float: none;
  margin-right: 10px;
  font-size: 14px;
}

.queryDiv .chapter {
  margin-right: 10px;
  font-size: 14px;
}

.sessionsDiv h2 {
  font-size: 15px;
  margin-bottom: 5px;
  margin-top: 0;
  color: #333;
}

.sessionsDiv p {
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 0;
}

.sessionsDiv span {
  font-size: 12px;
  background: #0079d2;
  padding: 5px 10px;
  border-radius: 10px;
  color: #fff;
  letter-spacing: 1px;
}

.sessionsDiv button {
  margin-top: 12px;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.16), 0 0px 0px 0 rgba(0, 0, 0, 0.12);
  background-color: #ea9f07;
  border-color: #ea9f07;
  width: 100%;
  padding: 5px;
  border-radius: 3px;
}
.card {
  margin: 25px;
  padding: 20px;
  width: 272px;
  height: 142px;
  top: 188px;
  left: 297px;
  border-radius: 8px;
  background: #fbb0b8;
}
.card2 {
  margin: 25px;
  padding: 20px;
  width: 272px;
  height: 142px;
  border-radius: 8px;
  background: #ffd59c;
}
.card3 {
  margin: 25px;
  width: 272px;
  height: 142px;
  padding: 20px;
  border-radius: 8px;
  background: #a9dbff;
}
.texts {
  color: rgba(0, 0, 0, 0.7);
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.circle {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  
}
.circle1 {
  width: 100%;
  height: 100px;
    width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  
}
.img_con {
  margin-top: 35px;
  height: 86px;
  background-color: white;
  padding: 2px;
  border-radius: 50%;
}

.filestudent {
  display: flex;
  gap: 2;
}
.totalcurri {
  display: flex;
  justify-content: space-between;
}
.totalstudent {
  display: flex;
  justify-content: space-between;
}
.totalsession {
  display: flex;
  justify-content: space-between;
}
.semicircle {
}
.totalassesment {
  display: flex;
  justify-content: center;
  width: 70%;
} */
.Dashboard_admindashome__dCRgG {
  /* margin-left: 60px; */
}

.Dashboard_main__3M6k5 {
  display: flex;
  justify-content: space-between;
}

.Dashboard_card__3QGrb {
  background: #a9dddd;
}

.Dashboard_curri__1_H3W {
  display: flex;
  justify-content: space-between;
}

.Dashboard_card2__3nTjh {
  background: #fbb0b8;
}

.Dashboard_card3__qSLRO {
  background: #ffd59c;
}

.Dashboard_common_card__2kdPv {
  width: 272px;
  height: 142px;
  padding: 20px;
  border-radius: 8px;
}

.Dashboard_submain__1UhD6 {
  display: flex;
  justify-content: space-between;

  /* padding-top: 3%;
  padding-left: 7%;
  padding-right: 7%; */
}

@media (max-width:1064px) {
  .Dashboard_common_card__2kdPv {
    width: 200px;
  }
}

.Dashboard_center_common_card__1OluA {
  height: 142px;
  padding: 20px;
  border-radius: 8px;
  width: 49% !important;
}

@media (max-width:880px) {
  .Dashboard_common_card__2kdPv {
    width: 80% !important;

  }

  .Dashboard_main__3M6k5 {
    flex-direction: column;
    align-items: center;
  }

  .Dashboard_submain__1UhD6 {
    flex-direction: column;
    align-items: center;
  }

  .Dashboard_center_common_card__1OluA {
    width: 80% !important;
    margin: 10px 0px;
  }

  .Dashboard_card2__3nTjh {
    margin: 20px 0px;
  }
}

@media (max-width:526px) {
  .Dashboard_center_common_card__1OluA {
    width: 100%;
  }

  .Dashboard_common_card__2kdPv {
    width: 100%;
  }
}

.Dashboard_assess__2g6js {
  display: flex;
  justify-content: space-between;
}


.Dashboard_sessio__1MtKm {
  display: flex;
  justify-content: space-between;
}




.Dashboard_card4__3VVAD {
  /* margin: 25px; */
  background: #CCBFEB;
}

.Dashboard_card5__1SqFp {
  /* margin: 25px; */
  background: #a9dbff;
}

.Dashboard_cardcompletedpend__3ztoU {
  display: flex;
  justify-content: space-around;

}

.Dashboard_sessioncards__mvqcA {
  display: flex;
  justify-content: space-around;
  width: 30%;
}

.Dashboard_queriescard__2Z0fD {
  display: flex;
  justify-content: space-around;
  width: 30%;

}

.Dashboard_completesession__3JIve {
  /* margin: 25px; */
  /* margin-left: 40px; */
  /* width: 200px; */
  height: 140px;
  padding: 20px;
  background: #CCBFEB;
  border-radius: 8px;

}

.Dashboard_pendingsession__3poy_ {
  /* margin: 25px; */
  /* width: 200px; */
  height: 140px;
  padding: 20px;
  background: #CCBFEB;
  border-radius: 8px;

}

.Dashboard_completequeries__1qXtX {
  /* margin: 25px; */
  /* width: 200px; */
  height: 140px;
  /* padding: 20px; */
  background: #A9DBFF;
  border-radius: 8px;
}

.Dashboard_pendingcard__2jmG_ {
  /* margin: 25px; */
  /* width: 200px; */
  height: 140px;
  padding: 20px;
  background: #A9DBFF;
  border-radius: 8px;
}

.Dashboard_student__AN5s0 {
  display: flex;
  justify-content: space-between;
}

.Dashboard_teacher__2jA5E {
  display: flex;
  justify-content: space-between;
}

totalSession {
  /* width: 50%; */
}

@media screen and (max-width: 1860px) {



  .Dashboard_sessioncards__mvqcA {
    display: flex;
    justify-content: space-around;
    width: 34%;
  }

  .Dashboard_queriescard__2Z0fD {
    display: flex;
    justify-content: space-around;
    width: 34%;

  }
}

@media screen and (max-width: 1650px) {
  .Dashboard_sessioncards__mvqcA {
    display: flex;
    justify-content: space-around;
    width: 38%;
  }

  .Dashboard_queriescard__2Z0fD {
    display: flex;
    justify-content: space-around;
    width: 38%;

  }

}

@media screen and (max-width: 1650px) {
  .Dashboard_sessioncards__mvqcA {
    display: flex;
    justify-content: space-around;
    width: 40%;
  }

  .Dashboard_queriescard__2Z0fD {
    display: flex;
    justify-content: space-around;
    width: 40%;

  }
}

@media screen and (max-width: 1450px) {
  .Dashboard_sessioncards__mvqcA {
    display: flex;
    justify-content: space-around;
    width: 42%;
  }

  .Dashboard_queriescard__2Z0fD {
    display: flex;
    justify-content: space-around;
    width: 42%;

  }
}

@media screen and (max-width:1400px) {
  .Dashboard_sessioncards__mvqcA {
    display: flex;
    justify-content: space-around;
    width: 45%;
  }

  .Dashboard_queriescard__2Z0fD {
    display: flex;
    justify-content: space-around;
    width: 45%;

  }
}

@media screen and (max-width:1350px) {
  .Dashboard_sessioncards__mvqcA {
    display: flex;
    justify-content: space-around;
    width: 48%;
  }

  .Dashboard_queriescard__2Z0fD {
    display: flex;
    justify-content: space-around;
    width: 48%;

  }
}

@media screen and (max-width:1300px) {




  .Dashboard_sessioncards__mvqcA {
    display: flex;
    justify-content: space-around;
    width: 48%;
  }

  .Dashboard_queriescard__2Z0fD {
    display: flex;
    justify-content: space-around;
    width: 48%;

  }
}

@media screen and (max-width:1250px) {


  .Dashboard_card4__3VVAD {
    height: 142px;
    background: #CCBFEB;


  }

  .Dashboard_card5__1SqFp {
    height: 142px;
    border-radius: 8px;
    background: #a9dbff;
  }


  .Dashboard_sessioncards__mvqcA {
    display: flex;
    justify-content: space-around;
    width: 50%;
  }

  .Dashboard_queriescard__2Z0fD {
    display: flex;
    justify-content: space-around;
    width: 50%;

  }
}
.TestImage_iconColor__3eVeQ {
  color: blue !important;
  /* border: 2px solid yellow !important; */
}

.TestImage_text_cap__1aQQ4{
  text-transform: capitalize !important;
}

.TestImage_overflow_list__2cad9{
  overflow: auto !important;
  height: 70vh !important;
}

.TestImage_main__gdgFD{
  *width: 100% !important;
  position: relative !important;
  
}
.TestImage_close_icon__2eHJ9{
  color: red !important;
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 15px !important;
  cursor: pointer;
}

.TestImage_img_popup__1dlLW{
  width: 40% !important;
  *height: 60vh !important;
  *background: green;
  position: absolute;
  left: 30% !important;
  top: 20vh !important;
}

.TestImage_pointer__2EY8x{
  cursor: pointer;
}
.TestImage_reject__3bFvm{
  color: red;
  font-weight: bold;
}
.TestImage_accept__2TJjq{
  color: blueviolet;
  font-weight: bold;
}
body{
    
    padding: 0 !important;
    margin: 0 !important;
}
.TeacherReport_MainDiv__SK1sc{
    background: #F1E3FE !important;
    height: 160vh !important;
    width: 100%;
    margin-bottom: 40px;
}
.TeacherReport_heading__3FXFt{
     width: 100% !important;
  padding: 10px 20px;
  background: #4A1E75;
}
.TeacherReport_assessmentHeading__1Juaz{
    margin-top: 3%;
    margin-left: 2%;
}
.TeacherReport_mainContainer__2SITr{
    /* border:2px solid red */
}
.TeacherReport_firstRow__3woTd{
    display: flex;
    justify-content: center;
    margin-top: 60px;
}
.TeacherReport_secondRow__1i4rF{
     display: flex;
    justify-content: center;
    height: 60vh;
    margin-top: 20px;
}
.TeacherReport_imgaICon__2Aoqt{
    /* font-size: 10px !important; */
    width: 50px;
}
/* .imgaICon4{
    font-size: 10px !important;
   
    height: 100px;

} */
.TeacherReport_vector1__2KJvz{
    background-color: #AB94E2;
   padding: 40px 60px;
    border-radius: 8px;
    margin-right: 20px;
    width:25vw
    /* display: flex; */
    /* justify-content: center;
    align-items: center;
    text-align: center; */
}
.TeacherReport_img1__2OvN_{
    background-color: #A9DDDD;
    border-radius: 50%;
    padding: 10px;
     width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    /* border: 2px solid red; */
     /* margin-left: 50px; */
     margin: 0 auto;

}
.TeacherReport_vector2__14abn{
    background-color: #AB94E2;
    /* padding: 40px; */
    border-radius: 8px;
    margin-left: 20px;
    padding: 40px 60px;
     width:25vw
}
.TeacherReport_img2__3VAZw{
    justify-content: center;
    background-color: #FBB0B8;
    border-radius: 50%;
    padding: 10px;
      width: 100px;
    height: 100px;
      margin: 0 auto;
}
.TeacherReport_vector3__300MK{
    background-color: #AB94E2;
    padding: 40px 63px;
    border-radius: 8px;
    height: 35vh;
    margin-right: 20px;
    width: 25vw;
}
.TeacherReport_img3__2MNmW{
    background-color: #FFD59C;
    border-radius: 50%;
     width: 100px;
    height: 100px;
    padding: 10px;
      margin: 0 auto;
   
}
.TeacherReport_vector4__bxoT7{
    background-color: #AB94E2;
     padding: 40px 66px;
    border-radius: 8px;
    height: 35vh;
    margin-left: 20px;
     width: 25vw;
}
.TeacherReport_img4__a8hjb{
      background-color: #A9DBFF;
      border-radius: 50%;
    width: 100px;
    height: 100px;
    padding: 10px;
      margin: 0 auto;
      
}
.TeacherReport_LastBox__gJg87{
    display: flex;
    justify-content: center;
}
.TeacherReport_testDuration__wDyS4{
    display: flex;
    justify-content: space-between;
    width: 55vw;
    background-color: #fff;
    border-radius: 8px;
    padding: 5px;
}
.Sidebar_mainSidebar__1FKh3 {
  position: fixed;
  left: 0px;
  top: 0px;
  bottom: 0px;
  background: #fff;
  overflow: hidden;
  width: 240px;
  z-index: 1030;
  box-shadow: 0 0 11px -6px rgb(0 121 210 / 65%);
}

.Sidebar_sidebar_links_container__20s_4{
  background: #4A1D75 !important;
}

.Sidebar_nav-link__1oJMN.Sidebar_active__2455s {
  color: #333 !important;
  background-color: #fff !important;
}
.Sidebar_nav-link__1oJMN:hover {
  color: white !important;
}

.Sidebar_logo__1s0t9 {
  text-align: center;
}

.Sidebar_logo__1s0t9 img {
  height: 45px;
  margin: 5px auto;
  display: block;
}

.Sidebar_logo__1s0t9 span {
  font-weight: 500;
  font-size: 14px;
  margin-top: 12px;
  display: block;
}

.Sidebar_mainSidebar__1FKh3 ul {
  padding-left: 15px;
  position: absolute;
  width: 100%;
  height: 100%;
  padding-left: 0px;
  top: 45px;
}

.Sidebar_mainSidebar__1FKh3 li {
  cursor: pointer;
  padding: 2px 0px;
  text-align: left;
}

.Sidebar_mainSidebar__1FKh3 .Sidebar_navLink__ZmbPE {
  font-size: 14px;
  text-decoration: none;
  /* color: #333; */
  color: white;
  font-weight: 500;
  padding: 10px 15px !important;
}

.Sidebar_sideIcon__2H8zX {
  font-size: 20px !important;
  margin-right: 8px;
  position: relative;
  top: 2px;
  /* color: #a2a2a2; */
  color: darkgray !important;
  /* color: white; */
}

.Topbar_navbar__2a_k8 {
  position: fixed;
  width: 100%;
  height: 60px;
  background: #fff;
  display: flex !important;
  align-items: center;
  box-shadow: 0 0.052083333in 11.25pt -9px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #e6e6e6;
}

.Topbar_dropdownMenu__1cvFl {
  right: 0px !important;
  left: auto !important;
  width: 400px;
  /* height: 8000px !important; */
  min-height: auto !important;
  height: auto !important;
 max-height: 270px !important;
}
.Topbar_dropdownMenu__1cvFl::-webkit-scrollbar{
  display:none;
}
.Topbar_dropdownMenu1__tvNTN {
  right: 0px !important;
  left: auto !important;
  /* width: 500px; */
}
.Topbar_navbarNav__1KbTr i {
  font-size: 25px;
}
.Topbar_teacheraccept__1mT5Y {
  padding-left: 2%;
  position: relative;
}
.Topbar_iconText__2k4cB:hover{
  /* display:flex; */
  /* align-items: center; */
  background: rgb(247, 247, 247);
}

.Sidebar_mainSidebar__wfJqz {
  position: fixed;
  left: 0px;
  top: 0px;
  bottom: 0px;
  background: #fff;
  overflow: hidden;
  width: 240px;
  z-index: 1030;
  box-shadow: 0 0 11px -6px rgb(0 121 210 / 65%);
}

.Sidebar_logo__3htur {
  text-align: center;
}

.Sidebar_logo__3htur img {
  height: 100px;
  margin: 5px auto;
  display: block;
}

.Sidebar_logo__3htur span {
  font-weight: 500;
  font-size: 14px;
  margin-top: 12px;
  display: block;
}

.Sidebar_mainSidebar__wfJqz ul {
  padding-left: 15px;
  position: absolute;
  width: 100%;
  height: 100%;
  padding-left: 0px;
  top: 140px;
  background-color: #222648;
}

.Sidebar_mainSidebar__wfJqz li {
  cursor: pointer;
  padding: 2px 0px;
}

.Sidebar_mainSidebar__wfJqz .Sidebar_navLink__2QU6Z {
  font-size: 14px;
  text-decoration: none;
  color: white;
  font-weight: 500;
  padding: 10px 15px !important;
}

.Sidebar_sideIcon__3_6cq {
  font-size: 18px;
  margin-right: 8px;
  position: relative;
  top: 2px;
  /* color: #a2a2a2; */
}

.Topbar_navbar__1zNA- {
  position: fixed;
  width: 100%;
  height: 60px;
  background: #fff;
  display: flex !important;
  align-items: center;
  box-shadow: 0 0.052083333in 11.25pt -9px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #e6e6e6;
}

.Topbar_dropdownMenu__q-ve2 {
  right: 0px !important;
  left: auto !important;
}

.Topbar_navbarNav__2yJrj i {
  font-size: 25px;
}

.theam-color {
  background: #4a1d75 !important;
  /* height: 100vh !important; */
  overflow-y: hidden !important;
}

.register {
  background: #fff;
  /* background: -webkit-gradient(
     linear,
     left bottom,
     right top,
     from(#fc2c77),
     to(#6c4079)
   );
   background: -webkit-linear-gradient(bottom left, #fc2c77 0%, #6c4079 100%);
   background: -moz-linear-gradient(bottom left, #fc2c77 0%, #6c4079 100%);
   background: -o-linear-gradient(bottom left, #fc2c77 0%, #6c4079 100%);
   background: linear-gradient(to top right, #fc2c77 0%, #6c4079 100%); */
  /* position: absolute; */
  right: 0px;
  left: 0px;
  top: 0px;
  bottom: 0px;
  height: 100%;
}

.register-left {
  text-align: center;
  color: #fff;
}

.register-form .label-heading,
.login-right .label-heading {
  display: none;
}

.register-left input {
  border: none;
  border-radius: 1.5rem;
  background: #f8f9fa;
  font-weight: bold;
  color: #383d41;
  cursor: pointer;
}

.register-right {
  background: #f8f9fa;
  border-top-left-radius: 10% 50%;
  border-bottom-left-radius: 10% 50%;
  margin-top: 15px;
}

.register .register-form {
  padding: 50px 100px;
}

.btnRegister {
  border: none;
  border-radius: 1.5rem;
  padding: 8px;
  background: #0f5ef6;
  color: #fff;
  font-weight: 500;
  width: 130px;
  letter-spacing: 1px;
  font-size: 15px;
}

.register-heading {
  text-align: center;
  color: #495057;
  padding-top: 25px;
  font-weight: 600;
}

.register-form hr {
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 0px solid rgb(49 69 186 / 8%);
}

.section-row {
  border: 1px solid #ececec;
  padding: 25px 10px;
  padding-bottom: 0px;
  background: #fff;
}

.login {
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(to top right, #0079d2 0%, #0079d2 100%);
}

.login-right {
  background: #fff;
  position: absolute !important;
  height: 100%;
  padding: 0px;
  right: 0px;
}

.welcome-elearn {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 60px;
  font-weight: 700;
  font-size: 26px;
}

.welcome-elearn span {
  color: #0f5ef6;
  font-weight: 900;
}

.login hr {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  border: 0;
  border-top: 1px solid rgb(49 69 186 / 8%);
  width: 60%;
}

.lw {
  padding: 8px 10px;
  color: #000;
  font-size: 17px;
  width: 230px;
  margin: 0 auto;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-bottom: 15px;
  cursor: pointer;
  font-weight: 500;
}

.lw img {
  height: 20px;
  margin-right: 10px;
}

.loginError {
  color: red;
  text-align: center;
  margin-bottom: 20px;
  margin-top: -18px;
}
.remove1 {
  border-radius: 3px;
  border: 1px solid;
}
.register1 {
  color: white;
}
.register1:hover {
  color: white;
  text-decoration: none;
}

.password {
  position: relative !important;
}

#eye_icon {
  position: absolute;
  top: 8px;
  right: 18px;
  cursor: pointer;
}

.errorMsg{
  position: absolute;
  top: 37px;
}
#eye_icon1 {
  position: absolute;
  top: 235px;
  left: 1850px;
}
@media only screen and (max-width: 1280px) {
  #eye_icon1 {
    left: 1050px;
  }
}
@media only screen and (max-width: 1350px) {
  #eye_icon1 {
    left: 1300px;
  }
}
@media only screen and (max-width: 1400px) {
  #eye_icon1 {
    left: 1190px;
  }
}
@media only screen and (max-width: 1450px) {
  #eye_icon1 {
    left: 1240px;
  }
}
@media only screen and (max-width: 1750px) {
  #eye_icon1 {
    left: 1330px;
  }
}
@media only screen and (max-width: 1850px) {
  #eye_icon1 {
    left: 1330px;
  }
}
@media only screen and (max-width: 2000px) {
  #eye_icon1 {
    left: 1220px;
  }
}
@media only screen and (max-width: 2100px) {
  #eye_icon1 {
    left: 1820px;
  }
}

.Place-Select {
  color: gray;
  width: 100%;
  height: 40px;
}

.login-left {
  display: flex;
  justify-content: center;
  /* width: 100%; */
  padding-top: 130px;
  *padding-right: 940px;
}


body{
  margin: 0;
  padding: 0;
}
.verify_card__1Lxrb {
  width: 40%;
  text-align: center;
}
.verify_cardImg__CJJdw {
  height: 100;
}
.verify_image__2xCr1 {
  margin: "23px auto";

  display: "block";
}
.verify_cardContainer__2JgdQ {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* height: 200vh; */
  padding-top: 14%;

}
.verify_emailcardbody__1I5aH{
  text-align: center !important;
  margin-top: 10px !important;
}
.verify_verifyemail__28Yo5{
  background-color: #E0F6F6 !important;
  color: #1DB9AA !important;
}


.main_container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    text-align: center;
}
.main_container .msg{
    font-size: 24px;
    font-weight: 400;
}
body {
  /* before */
  /* font-family: "Roboto", sans-serif; */
  /* after */
  font-family: "Lato";
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.3px;
}
/* .btn-primary {
  background: #c5f9d5 ;
  color: #363636 !important;
} */
.btn-Primary{
  /* background: #4A1D75 !important; */
  color: white !important;
}
#containerform {
  margin-top: 20px;
  border: 1px solid black;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 6px;
}
.uttam {
  width: 100%;
}

.row {
  margin: 0px;
}

.pad-0 {
  padding: 0px;
}

.mb-35 {
  margin-bottom: 25px;
}

p {
  margin-bottom: 0px;
}

a {
  color: #0079d2;
}

.btn {
  /* color: #fff !important; */

  background: #222648;
  /* text-transform: uppercase; */
  word-wrap: break-word;
  white-space: normal;
  cursor: pointer;
  border: 0;
  border-radius: 3px;
  /* box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%); */
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  /* padding: 0.6rem 1.5rem; */
  font-size: 0.81rem;
  line-height: 1.5;
  font-weight: 700;
  /* width: 203px; */
  /*  height: 53px; */
}
tbody .view_icon i {
  padding-right: 0.2rem;
  font-size: 10px;
}
/* tbody td{
  font-weight: 600;
} */
.btn-right {
  text-transform: uppercase;
  margin-left: auto !important;
  margin-right: 15px;
  display: block;
  font-size: large;
}
@media screen and (min-width: 412px) {
  .btn-right {
    text-transform: uppercase;
    margin-left: auto !important;
    margin-right: 110px;
    display: block;
    font-size: large;
    margin-top: 12px;
  }
}
@media screen and (min-width: 375px) {
  .btn-right {
    text-transform: uppercase;
    margin-left: auto !important;
    margin-right: 50px;
    display: block;
    font-size: large;
    /* margin-top: 12px; */
  }
}

.btn-sm {
  padding: 0.3rem 0.85rem;
  font-size: 0.75rem;
  line-height: 1.5;
  letter-spacing: 0.4px;
}

.btn-success {
  color: #4a1e75;
  background-color: #ebd8fe;
  border-color: #ebd8fe;
}
.btn-danger {
  color: #ff3044;
  background-color: #ffced3;
  border-color: #ffced3;
}
.btn-success:hover {
  background-color: #e0c4fc;
  color: #4a1e75;
}

.btn-info {
  color: #fff ;
  background-color: #222648 ;
  border-color: #222648 ;
}
.btn-danger:hover {
  color: #ff3044 !important;
  background-color: #fcbec4 !important;
  border-color: #fcbec4 !important;
}
.btn-info:hover {
  color: #222648 ;
  background-color: white ;
  border-color: white ;
}

.btn-primary {
  color: #fff;
  background-color: #4A1D75 !important;
  border-color: #0079d2 !important;
}

.form-group {
  margin-bottom: 1.8rem;
}

.form-control {
  font-size: 15px !important;
  height: 38px;
  border-radius: 6px;
  width: 100% !important;
}

.formSelect {
  font-size: 15px !important;
  height: 38px;
  border-radius: 6px;
}

.label-heading {
  margin-bottom: 0.2rem;
  margin-top: 1px;
  font-size: 14px;
  font-weight: 500;
  color: #545454;
}

.content-wrapper {
  transition: margin 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, margin 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, margin 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  margin-left: 240px;
  z-index: 820;
  padding-top: 60px;
  /*  padding: 20px 5px; */
  min-height: 500px;
  background: #f7f7f7;
  /* background: #f6f7fb; */
  overflow: hidden;
}

.text-heading {
  font-size: 18px;
  font-weight: 500;
  color: #333;
  margin-left: 15px;
  margin-bottom: 10px;
  /* margin-top: 20px; */
}
.text-heading1 {
  font-size: 18px;
  font-weight: 500;
  color: #333;
  margin-left: 7px;
  margin-bottom: 10px;
  /* margin-top: 20px; */
}

.viewMore {
  float: right;
  margin-left: auto !important;
  margin-right: 15px;
  margin-bottom: 0px;
  cursor: pointer;
  position: relative;
  font-weight: 500;
}

/* .viewMore a {
   color: #0079d2; 
} */

.viewMore i {
  font-size: 18px;
  position: relative;
  top: 1px;
}
#buttonItem {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 25px;
  margin-left: -22px;
}
/* .tabBotton {
  background: #fff !important;
  color: #222648 !important;
  border: 1px solid #222648 !important;
  border-radius: 3px !important;
  cursor: pointer !important;
  height: 20px !important;
} */

.tabItem {
  /* float: left; */
  /* background: #fff; */
  color: #222648;
  /* border: 1px solid #222648; */
  /* padding: 7px 10px; */
  /* margin-right: 80px; */
  /* border-radius: 3px; */
  /* font-weight: 300; */
  /* cursor: pointer; */
  /* height: 38px; */

  float: left;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  border: 1px solid #222648;
  padding: 6px 20px;
  margin-right: 10px;
  border-radius: 30px !important;
  cursor: pointer;
  /* box-sizing: border-box; */
  width: auto;
  height: 40px;
  /* height: 53px; */
  /* left: 34px;
  top: 198px; */
  background: #4a1e75;
  /* border: 1px solid #ffffff; */
  /* border-radius: 12px; */
}
.query_top {
  background-color: #0079d2;
}
.tabItemActive {
  background: #f1e3fe;
  color: #4a1e75;

  border-style: double;
  border-color: #4a1e75;
}

.error {
  color: red;
  font-size: 13px;
  margin-bottom: -20px;
  border: none !important;
}

.nav-link.active {
  /* background-color: #e3f3ff; */
  background: rgb(0 121 210 / 16%);
  color: #222648;
}

/* .nav-link:hover {
  background: rgb(0 121 210 / 16%);
  color: #888;
} */

/* .nav-link.active i {
  color: #222648;
} */

.event__ACCEPTED {
  color: #fff;
  /* font-weight: bold; */
}

.event__REJECTED {
  color: #fff;
  /* font-weight: bold; */
}

.event__PENDING {
  color: #fff;
  /* font-weight: bold; */
}

.color-status span {
  height: 40px;
  display: inline-block;
  font-weight: bold;
  margin-right: 20px;
}

.color-status span.color-pending {
  color: #03a9f4;
}

.color-status span.color-accepted {
  color: #4caf50;
}

.color-status span.color-rejected {
  color: red;
}

.rbc-event-label {
  display: none !important;
}

/* .rbc-event {
  padding: 4px 3px !important;
  background-color: #eab731 !important;
  border: 1px solid #eab731 !important;
  border-radius: 0px;
} */

.rbc-day-slot .rbc-events-container {
  margin-right: 0px !important;
}

/* 
.event__ACCEPTED,
.event__REJECTED,
.event__PENDING {
  color: #fff;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  letter-spacing: 0.5px;
}

.color-status span {
  height: 40px;
  display: inline-block;
  font-weight: 600;
  margin-right: 15px;
}

.color-status span.color-pending {
  color: #03a9f4;
}

.color-status span.color-accepted {
  color: #4caf50;
}

.color-status span.color-rejected {
  color: #f44336;
}

.rbc-event-label {
  display: none !important;
}

.rbc-event {
  padding: 4px 3px !important;
} */

.exists-acc {
  text-align: center;
  margin-top: 30px;
}
.font-size {
  font-size: 30px;
}
.react-datepicker__time-container {
  width: 150px;
}
.copy_btn {
  position: relative;
  display: inline-block;
}
.copy_menu {
  position: absolute;
  top: 23px;
  left: -200px;
  background-color: white;
  visibility: hidden;
  z-index: 2;
  width: 355px;
  height: 60px;
  box-shadow: 0px 0px 20px rgb(188, 186, 186);
  border-radius: 10px;
}
.threedotbtn {
  z-index: -1;
  margin: 0px 0px 0px 8px;
  font-size: 20px;
  cursor: pointer;
}

.threedotbtn:hover {
  box-shadow: 0px 0px 10px rgb(163, 162, 162);
}
.threedotbtn:hover ~ .copy_menu {
  visibility: visible;
}
.copy_menu li {
  list-style: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 30px;
  font-size: 15px;
  cursor: pointer;
}
.copy_menu:hover {
  visibility: visible;
}
.copy_menu li:hover {
  color: #0079d2;
}

/*==================calender new added css============== */
.card .card-body .color_status_div {
  border: 2 px solid red !important;
}
.cal_con .active {
  color: red !important;
}

/* ========================query new added style============== */
.query_head {
  background: #f1e3fe;
  border-radius: 10px;
}

.pointer {
  cursor: pointer !important;
}

.cancel {
  background: #ffccd1;
  color: #ff1b49;
  line-height: 20px;
  border: none;
  padding: 6px 10px;
  font-weight: 700;
  border-radius: 3px;
  margin-top: 3px;
}

.form-heading {
  color: #501a74;
  font-size: 24px;
  font-weight: 600;
}

.scroll_container {
  height: 90vh;
  overflow-y: scroll;
  border-top: 1px solid lightgray;
}
/* width */
.scroll_container::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.scroll_container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.scroll_container::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.scroll_container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.view_btn {
  background: #b4fbd7;
  padding: 4px 7px;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 2px;
}

/* .active {
  background: #501a74 ;
} */
.active {
  background-color: lightgray !important;
  padding: 5px 10px;
  border-radius: 5px;
  /* padding-bottom: 1px; */
}

#table{
  width: 100%;
  min-width: 70vw !important;
  overflow-x: auto;
}

.toggle_dot {
  width: 10px !important;
  height: 10px !important;
  border-radius: 100% !important;
  background: #f1e3fe;
  margin-right: 10px;
}

.customH {
  height: 100% !important;
}
.customH img {
  width: 100%;
  height: auto;
}

.main_box {
  /* border: 1px solid red; */
  width: 100%;
  /* border: 2px solid red; */
  margin: 0% 2%;
  position: relative;
  /* height: 200px; */
}
.img_box {
  width: 80px;
  height: 80px;
  /* position: absolute; */
  /* padding: 20px; */
  /* border-radius: 100%; */
  /* background: blue; */
  /* top: 18%; */
  /* left: 36%; */
}
.img_box1 {
  width: 80px;
  height: 80px;

  /* position: absolute; */
  /* padding: 20px; */
  /* border-radius: 100%; */
  /* background: blue; */
  /* top: 18%; */
  /* left: 36%; */
}

.dropdown-menu {
  height: auto !important;
  overflow-y: auto !important;
}

/* .navbar-nav >.nav-item >.nav-link.active> li { 
    background-color: red ; 
} */

.test {
  /* background-color: #888 !important; */
  text-decoration: none !important;
  color: #222 !important;
  font-family: roboto !important;
}
/* li{
  text-decoration: none;
} */
/* .nav-item {
  display: flex;
  justify-content: space-between;
} */

