.navbar {
  position: fixed;
  /* z-index: 999; */
  width: 100%;
  height: 60px;
  background: #fff;
  display: flex !important;
  align-items: center;
  box-shadow: 0 0.052083333in 11.25pt -9px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #e6e6e6;
}
.navDashboard{
   font-family: roboto !important;
   /* text-transform: capitalize; */
}

.dropdownMenu {
  right: 0px !important;
  left: auto !important;
  width: 400px;
  min-height: auto !important;
  height: auto !important;
  max-height: 270px !important;
}
.dropdownMenu::-webkit-scrollbar{
  display:none;
}
.dropdownMenu1 {
  right: 0px !important;
  left: auto !important;
  /* width: 500px; */
}

.navbarNav i {
  font-size: 25px;
}
.rejectAdmin{
  padding-left: 2%;
  position: relative;
}
.notificationnn:hover{
  background: rgb(247, 247, 247);
}