.date {
  background-color: #4a1d75;
  border-radius: 50px;
  padding: 3px 20px;
  color: rgb(255, 255, 255);
}
.labelStyle{
  font-weight: 500;
    color: #545454;
}
.head {
  font-size: 18px;
}
.num {
  font-size: 23px;
  text-align: center;
}
.click2 {
  height: 30px;
  margin: 3px;
  /* padding: 50px; */
  background-color: #e0f6f6 !important;
  color: #1db9aa !important;
  border: 2px solid #e0f6f6;
}
.click1 {
  height: 30px;
  margin: 3px;
  /* padding: 50px; */
  background-color: #cbb4b4 !important;
  color: red !important;
  border: 2px solid #cbb4b4;
}
.acceptedTecher {
  max-height: 150px;
  overflow: scroll;
}

.teacherLi {
  display: flex;
  flex-direction: row;
  gap: 30px;
  background-color: red;
}
.adminSession {
  color: #20639b;
}
.sessionButton {
  /* border: 2px solid red; */
}
@media (max-width: 1126px) {
  .sessionButton {
    /* flex-direction: column ;
    align-items: center; */
    display: flex;
    flex-direction: row;
    height: 60px;
    /* justify-content: space-between; */
  }
  .date {
    width: 120px !important;
  }
  .dateResponsive {
    display: flex;
    justify-content: space-between;
  }
  .head{
    font-size: 14px;
  }
}
@media (max-width: 900px) {
  .mainAdminSession {
    flex-direction: column;
    /* text-align: center; */
    /* align-items: center; */
  }
  .dateSession {
    width: 400px !important;
  }
  .TotalSesion {
    width: 400px !important;
    margin-top: 30px !important;
  }
  .sessionButton {
    width: 400px !important;
    margin-top: 30px !important;
    align-items: center;
  }
  .date {
    width: 150px !important;
  }
  .dateResponsive {
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width:606px){
  .date{
    width: 120px !important;
  }
}
